import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './footer.component';

const material = [MatToolbarModule, MatIconModule, MatButtonModule];

@NgModule({
    declarations: [FooterComponent],
    imports: [CommonModule, ...material, TranslateModule, RouterModule],
    exports: [FooterComponent],
})
export class FooterModule {}
