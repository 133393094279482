import { Component } from '@angular/core';
import { StatusSummary } from '../../services/status-page.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
    public type: string;
    constructor() {
        const state: StatusSummary = history.state;
        this.type = state?.status?.toUpperCase() || 'UNDER_MAINTENANCE';
    }
}
