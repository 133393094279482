import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';
import { QuestConfig, QuestModule } from '@vi/quest';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxUploaderModule } from 'ngx-uploader';
import { environment } from '../../../environments/environment';
import { InstanaService } from '../../services/instana.service';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ConflictingValueDialogComponent } from './components/conflicting-value-dialog/conflicting-value-dialog.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ComponentSelectionDialogComponent } from './components/order-search/component-selection-dialog/component-selection-dialog.component';
import { OrderSearchDialogComponent } from './components/order-search/order-search-dialog/order-search-dialog.component';
import { OrderSearchComponent } from './components/order-search/order-search.component';
import { ThanksComponent } from './components/thanks/thanks.component';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { ConfigurationComponent } from './configuration.component';
import { ConfigitApiService } from './services/configit-api.service';
import { ConfigitQuestAdapterService } from './services/configit-quest-adapter.service';

// quest config
const questConfig: QuestConfig = {
    context: environment.quest.context,
    behavior: {
        allowAnswerReset: false,
        answerResetLabel: '-',
        disableOnCheck: true,
        restrictPartChange: false,
        floatInputIncrement: 0.1,
        showImagePlaceholder: true,
    },
    file: {
        uploadUrl: '',
        csrfTokenLocalStorageKey: 'ap_csrf_token',
    },
};

const matModules = [
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    ScrollingModule,
    MatTooltipModule,
    MatExpansionModule,
];

@NgModule({
    declarations: [
        ConfigurationComponent,
        ConflictingValueDialogComponent,
        ConfirmationComponent,
        ThanksComponent,
        OrderSearchComponent,
        ComponentSelectionDialogComponent,
        OrderSearchDialogComponent,
        VideoDialogComponent,
        DashboardComponent,
    ],
    imports: [
        TranslateModule.forChild(),
        FlexModule,
        QuestModule.forRoot(questConfig),
        ConfigurationRoutingModule,
        CommonModule,
        NgxSkeletonLoaderModule,
        ...matModules,
        NgxUploaderModule,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'l',
                },
                display: {
                    dateInput: 'DD.MM.YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'L',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        ConfigitQuestAdapterService,
        ConfigitApiService,
        InstanaService,
    ],
})
export class ConfigurationModule {}
