import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of, shareReplay } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { InstanaService } from './instana.service';

// Declare interface for Google Analytics 4
declare global {
    interface Window {
        dataLayer: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class IdentityService {
    invalidUser$ = new BehaviorSubject<boolean>(false);

    public user$ = this.http
        .get<Me>(`${environment.http.users}users/me`, {
            params: {
                sections: ['identity', 'roles', 'sales'],
            },
        })
        .pipe(
            map((user) => {
                const identityType =
                    user?.properties
                        .find((item: { name: string; value: string }) => item.name === 'IdentityType')
                        ?.value?.toLowerCase() || '';
                if (!identityType.toLowerCase().startsWith('vi')) {
                    this.instana.reportUser(user.id, user.loginId, user.contacts.email);
                    this.instana.reportMetadataObject({
                        displayName: user.name && `${user.name.firstName} ${user.name.familyName}`,
                        company: user.company?.id,
                        identityType:
                            user.properties
                                .find((item: { name: string; value: string }) => item.name === 'IdentityType')
                                ?.value?.toLowerCase() || '',
                        iamRoles: user.applicationRoles?.join(', ') || '',
                    });
                }

                // Push user data for Google Analytics 4 dataLayer
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'user_profile_loaded',
                    identityType: identityType,
                    userId: user.id,
                    userProfileLoaded: true,
                });

                return {
                    ...user,
                    identityType:
                        user.properties
                            .find((item: { name: string; value: string }) => item.name === 'IdentityType')
                            ?.value?.toLowerCase() || '',
                };
            }),
            shareReplay()
        );

    constructor(private http: HttpClient, private router: Router, private instana: InstanaService) {}

    public hasAdminProfiAccess$ = this.user$.pipe(
        map((user) => {
            const identityType =
                user.properties
                    .find((item: { name: string; value: string }) => item.name === 'IdentityType')
                    ?.value?.toLowerCase() || '';

            if (identityType.toLowerCase() === 'kuma') {
                return (
                    !user.businessRoles?.includes('CUBR_Default_LiteAccount') &&
                    environment.availableCountries.includes(user.address?.countryCode?.toUpperCase())
                );
            } else {
                return ['viint', 'viext'].includes(identityType.toLowerCase());
            }
        }),
        catchError((err) => {
            this.invalidUser$.next(true);
            this.router.navigate(['/incorrect-customer-data']);
            return of(err);
        })
    );

    public isAdmin$ = this.user$.pipe(
        map((user) => {
            return !!user.applicationRoles?.includes('Adminprofi.Admin');
        })
    );

    public identityType$ = this.user$.pipe(
        map((user) => {
            return (
                user.properties
                    .find((item: { name: string; value: string }) => item.name === 'IdentityType')
                    ?.value?.toLowerCase() || ''
            );
        })
    );
}

export interface Me {
    id: string;
    loginId: string;
    name: {
        title: string;
        firstName: string;
        familyName: string;
    };
    address: {
        street: string;
        houseNumber: string;
        postalCode: string;
        city: string;
        countryCode: string;
    };
    company: {
        name: string;
        countryCode: string;
        id: string;
        vkorg: string;
    };
    contacts: {
        email: string;
        mobile: CustomerContactData;
        telephone: CustomerContactData;
    };
    userState: string;
    languageCode: string;
    locale: string;
    properties: { name: string; value: string }[];
    applicationRoles?: string[];
    businessRoles?: string[];
    contactId?: string;
    identityType?: string;
}

export interface CustomerContactData {
    country: string;
    number: string;
    extension?: string;
}
