import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../services/auth.service';
import { ConfigAttachment, ConfigurationService } from '../../services/configuration.service';
import { DashboardService } from '../../services/dashboard.service';

type UploadFileFormControl =
    | {
          name?: string;
          size?: string;
      }
    | null
    | undefined;
export interface UploadFileControl {
    file: UploadFile | undefined;
    control: FormControl<UploadFileFormControl>;
    uploading: Uploading | boolean | undefined;
    sectionId?: string;
    queued?: boolean;
    deleting?: boolean;
    owner?: string;
}
export interface UploadSection {
    id: string;
    multiple?: boolean;
    options?: UploaderOptions;
}

export interface Uploading {
    percentage: number;
    speed: number;
    speedHuman: string;
    startTime: number | null;
    endTime: number | null;
    eta: number | null;
    etaHuman: string | null;
}

const MAX_FILE_SIZE = 10485760;
const allowedContentTypes = ['application/pdf'];
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    @Input() public isAdmin!: boolean;
    @Input()
    public configurationId!: string;
    commissioningDate: string | undefined;
    disableCustomerCommissioningDate = false;
    sectionUploading = false;
    sectionUploaded: string | undefined;
    uploadSections: UploadSection[] = [
        {
            id: 'eplanAdmin',
            multiple: false,
            options: {
                allowedContentTypes: allowedContentTypes,
                concurrency: 1,
                maxUploads: 1,
            },
        },
        {
            id: 'commissioningDocumentsAdmin',
            multiple: true,
            options: {
                allowedContentTypes: allowedContentTypes,
                concurrency: 3,
                maxUploads: 3,
            },
        },
        {
            id: 'commissioningDocumentsCustomer',
            multiple: true,
            options: {
                allowedContentTypes: allowedContentTypes,
                concurrency: 3,
                maxUploads: 3,
            },
        },
        {
            id: 'commissioningDateCustomer',
        },
        {
            id: 'miscellaneousAdmin',
            multiple: true,
            options: {
                allowedContentTypes: allowedContentTypes,
                concurrency: 3,
                maxUploads: 6,
            },
        },
    ];
    uploadFileControls: UploadFileControl[] = [];
    uploadAttachments: ConfigAttachment[] = [];
    uploading = false;

    @Output() startUpload: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

    @ViewChild('input', { static: true }) input!: ElementRef<HTMLInputElement>;

    dragging: boolean = false;

    constructor(
        private configurationService: ConfigurationService,
        private authService: AuthService,
        private dashboardService: DashboardService,
        private snackbar: MatSnackBar,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.refreshFileList();
        this.configurationService.getConfig(this.configurationId).subscribe((res) => {
            this.commissioningDate = res.commissioningDate;
            if (!this.isAdmin && this.commissioningDate) {
                this.disableCustomerCommissioningDate = true;
            }
        });
    }

    public setCommissioningDate(event: MatDatepickerInputEvent<Date>) {
        this.commissioningDate = event.value?.toString();
    }

    public saveDateSection() {
        this.sectionUploading = true;
        this.configurationService
            .updateConfiguration(this.configurationId, {
                commissioningDate: this.commissioningDate,
            })
            .subscribe((res) => {
                this.sectionUploading = false;
                this.commissioningDate = res.commissioningDate;
                if (!this.isAdmin) {
                    this.disableCustomerCommissioningDate = true;
                }
                this.openSnackbar(
                    this.translate.instant('ADMIN_PROFI.DASHBOARD.SNACKBAR.DATE_SUCCESS'),
                    'save-success-snackbar'
                );
            });
    }

    public saveUploadSection(sectionId: string) {
        this.sectionUploaded = sectionId;
        this.sectionUploading = true;
        this.dashboardService.notifyAttachmentUpload(this.configurationId, sectionId).subscribe(
            (notified) => {
                if (notified) {
                    this.sectionUploading = false;
                    this.openSnackbar(
                        this.translate.instant('ADMIN_PROFI.DASHBOARD.SNACKBAR.NOFITY_SUCCESS'),
                        'save-success-snackbar'
                    );
                }
            },
            (error) => {
                if (error) {
                    this.sectionUploading = false;
                    this.openSnackbar(
                        this.translate.instant('ADMIN_PROFI.DASHBOARD.SNACKBAR.NOFITY_ERROR'),
                        'save-error-snackbar'
                    );
                }
            }
        );
    }

    public isPending(sectionId: string) {
        return this.sectionUploaded === sectionId && this.sectionUploading;
    }

    public manageAccess(sectionId: string) {
        if (sectionId === 'commissioningDocumentsCustomer') {
            if (!this.isAdmin) {
                return !this.atLeastOneFileInSection('commissioningDocumentsAdmin');
            } else {
                return false;
            }
        } else {
            return !this.isAdmin;
        }
    }

    private openSnackbar(errorMsg: string, panelClass: string) {
        this.snackbar.open(errorMsg, '', {
            panelClass: [panelClass],
            duration: 3000,
        });
    }

    refreshFileList(): void {
        this.configurationService.getConfig(this.configurationId).subscribe((res) => {
            this.uploadFileControls = [];
            this.uploadAttachments = [];
            res.attachments.reverse().forEach((attachment) => {
                if (attachment.sectionId) {
                    const name = attachment.fileName;
                    const file = {
                        id: attachment.attachmentId,
                        name,
                    };
                    this.uploadFileControls.push({
                        control: new FormControl<UploadFileFormControl>({ name }),
                        file: <UploadFile>file,
                        uploading: false,
                        sectionId: attachment.sectionId.slice(0, attachment.sectionId.indexOf('#')),
                    });
                    this.uploadAttachments.push(attachment);
                }
            });
            this.addFileControl();
            this.uploading = false;
        });
    }

    addFileControl(file?: UploadFile, sectionId?: string) {
        if (file && this.uploadFileControls.length) {
            const last = this.uploadFileControls[this.uploadFileControls.length - 1];
            last.file = file;
            last.queued = true;
            last.sectionId = `${sectionId!}#${file.id}`;
        }
        this.uploadFileControls.push({
            control: new FormControl<UploadFileFormControl>(undefined),
            file: undefined,
            uploading: false,
        });
    }

    protected invalid(uploadFileControl: UploadFileControl): ValidationErrors | null {
        if (uploadFileControl.file && uploadFileControl.file.size > MAX_FILE_SIZE) {
            this.set(uploadFileControl); // set for display
            return {
                max: { max: humanizeBytes(MAX_FILE_SIZE) },
            };
        }
        return null;
    }

    protected start(): void {
        this.uploadFileControls.forEach((uploadFile) => {
            const invalid = this.invalid(uploadFile);
            if (invalid) {
                this.startUpload.emit({ type: 'remove', id: uploadFile.file!.id });
                uploadFile.control.setErrors(invalid);
            }
        });
        this.uploadFileControls.forEach((uploadFile) => {
            if (uploadFile.queued) {
                this.startUpload.emit({
                    url: environment.file.uploadUrl.replace('{id}', this.configurationId || ''),
                    type: 'uploadFile',
                    method: 'POST',
                    headers: {
                        Authorization: `CSRF ${this.authService.csrfToken$.value}`,
                    },
                    data: {
                        sectionId: uploadFile.sectionId!,
                    },
                    file: uploadFile.file,
                });
            }
        });

        this.dragging = false;
    }

    protected set(uploadFileControl: UploadFileControl): void {
        const file = uploadFileControl.file;
        const name = file!.name;
        const size = humanizeBytes(file!.nativeFile!.size);
        uploadFileControl.control.setValue({ name, size });
    }

    find(file: UploadFile) {
        return this.uploadFileControls.find((ufc) => ufc.file!.id === file.id)!;
    }

    protected success(status: number): boolean {
        return status >= 200 && status < 400;
    }

    uploadInProgress(): boolean {
        return this.uploadFileControls.some((ufc) => ufc.uploading);
    }

    atLeastOneFileInSection(sectionId: string) {
        return this.uploadFileControls.length > 1 && this.uploadFileControls.some((ufc) => ufc.sectionId === sectionId);
    }

    deleteAttachment(uploadFileControl: UploadFileControl): void {
        uploadFileControl.deleting = true;
        this.uploading = uploadFileControl.deleting;
        this.dashboardService.deleteConfigurationAttachment(this.configurationId, uploadFileControl.file!.id).subscribe(
            (deleted) => {
                if (deleted) {
                    this.refreshFileList();
                }
            },
            (error) => {
                if (error) {
                    uploadFileControl.deleting = false;
                    this.uploading = uploadFileControl.deleting;
                }
            }
        );
    }

    cancel(uploadFileControl: UploadFileControl): void {
        const index = this.uploadFileControls.indexOf(uploadFileControl);
        this.uploadFileControls.splice(index, 1);
        if (this.input) {
            this.input.nativeElement.type = '';
            this.input.nativeElement.type = 'file';
        }
        this.startUpload.emit({ type: 'cancel', id: uploadFileControl.file!.id });
    }

    downloadUrl(attachmentId: string): string {
        return environment.file.downloadUrl
            .replace('{id}', this.configurationId || '')
            .replace('{attachmentId}', attachmentId);
    }

    onUpload(event: UploadOutput, sectionId: string): void {
        switch (event.type) {
            case 'cancelled': {
                break;
            }
            case 'dragOver': {
                this.dragging = true;
                break;
            }
            case 'dragOut': {
                this.dragging = false;
                break;
            }
            case 'allAddedToQueue':
                return this.start();
            case 'addedToQueue': {
                this.addFileControl(event.file, sectionId);
                this.uploading = true;
                break;
            }
            case 'uploading': {
                const ufc = this.find(event.file!);
                ufc!.uploading = event.file!.progress.data;
                ufc!.queued = false;
                break;
            }
            case 'done': {
                const file = event.file;
                const uploadFile = this.find(file!);
                this.set(uploadFile!);

                if (!this.success(file!.responseStatus!)) {
                    uploadFile!.control.setErrors({ upload: file });
                }

                this.find(event.file!).uploading = undefined;
                const uploadInProgress = this.uploadInProgress();
                if (!uploadInProgress) {
                    this.refreshFileList();
                }
                break;
            }
        }
    }
}
