import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IdentityService } from '../../../../services/identity.service';

@Component({
    selector: 'app-thanks',
    templateUrl: './thanks.component.html',
    styleUrls: ['./thanks.component.scss'],
})
export class ThanksComponent implements OnInit {
    @Input() isEplan = false;
    @Input() projectId?: string;
    @Input() configId?: string;
    @Input() isAdmin = false;

    downloadConfiguration = environment.file.configurationDownload;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private identityService: IdentityService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        combineLatest([this.route.params, this.identityService.user$])
            .subscribe(([params, user]) => {
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: { projektid: params['project'] },
                    // ADMIN_PROFI.THANKS.SURVEY.INTERCEPT_FRAGMENT - vielendank_
                    fragment: `${this.translateService.instant('ADMIN_PROFI.THANKS.SURVEY.INTERCEPT_FRAGMENT')}${
                        user.id
                    }`,
                });
            })
            .unsubscribe();
    }

    getDownloadUrl() {
        return environment.file.configurationDownloadUrl.replace('{id}', this.configId || '');
    }
}
