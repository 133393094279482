<mat-card appearance="outlined" class="mb-3">
    <mat-card-header>
        <div class="row d-flex align-items-center">
            <h3>
                {{ 'ADMIN_PROFI.ORDER_SEARCH.TITLE' | translate }}
            </h3>
        </div></mat-card-header
    >
    <mat-card-content>
        <ng-container *ngIf="!ordersLoading">
            <form [formGroup]="orderForm" class="mt-2 px-3">
                <div formArrayName="orderNumbers">
                    <div
                        *ngFor="
                            let form of orderNumbersFormArray.controls;
                            index as i;
                            let first = first;
                            let last = last
                        "
                    >
                        <div class="d-flex flex-row align-items-center">
                            <mat-form-field>
                                <input
                                    [formControlName]="i"
                                    [placeholder]="'ADMIN_PROFI.ORDER_SEARCH.INPUT.PLACEHOLDER' | translate"
                                    [readonly]="
                                        orderNumbersFormArray.controls[i].valid &&
                                        !!orderNumbersFormArray.controls[i].value
                                    "
                                    matInput
                                />
                                <mat-spinner
                                    *ngIf="
                                        (orderNumbersFormArray.controls[i].value &&
                                            orderNumbersFormArray.controls[i].pending) ||
                                        loading
                                    "
                                    [diameter]="20"
                                    color="accent"
                                    matSuffix
                                ></mat-spinner>
                                <mat-icon
                                    *ngIf="
                                        orderNumbersFormArray.controls[i].value &&
                                        orderNumbersFormArray.controls[i].valid &&
                                        !loading
                                    "
                                    class="success"
                                    matSuffix
                                    >check</mat-icon
                                >
                                <mat-icon
                                    *ngIf="
                                        orderNumbersFormArray.controls[i].value &&
                                        orderNumbersFormArray.controls[i].invalid &&
                                        !loading
                                    "
                                    color="error"
                                    matSuffix
                                    >close</mat-icon
                                >
                                <mat-error *ngIf="orderNumbersFormArray.controls[i].hasError('notFound')">
                                    {{ 'ADMIN_PROFI.ORDER_SEARCH.INPUT.ERROR.NOT_FOUND' | translate }}
                                </mat-error>
                                <mat-error *ngIf="orderNumbersFormArray.controls[i].hasError('notRelevant')">
                                    {{ 'ADMIN_PROFI.ORDER_SEARCH.INPUT.ERROR.NOT_RELEVANT' | translate }}
                                </mat-error>
                                <mat-error *ngIf="orderNumbersFormArray.controls[i].hasError('alreadyInUse')">
                                    {{ 'ADMIN_PROFI.ORDER_SEARCH.INPUT.ERROR.ALREADY_IN_USE' | translate }}
                                </mat-error>
                                <mat-error *ngIf="orderNumbersFormArray.controls[i].hasError('required')">
                                    {{ 'ADMIN_PROFI.ORDER_SEARCH.INPUT.ERROR.REQUIRED' | translate }}
                                </mat-error>
                            </mat-form-field>
                            <button
                                (click)="addRow()"
                                [disabled]="
                                    !orderNumbersFormArray.controls[i].valid ||
                                    loading ||
                                    !last ||
                                    !orderNumbersFormArray.controls[i].value
                                "
                                [matTooltip]="'ADMIN_PROFI.ORDER_SEARCH.TOOLTIP.ADD_ORDER' | translate"
                                mat-icon-button
                            >
                                <mat-icon>library_add</mat-icon>
                            </button>
                            <button
                                (click)="deleteRow(i)"
                                [disabled]="
                                    (orderNumbersFormArray.invalid && orderNumbersFormArray.controls[i].valid) ||
                                    loading ||
                                    orderNumbersFormArray.controls[i].pending ||
                                    !orderNumbersFormArray.controls[i].value
                                "
                                [matTooltip]="'ADMIN_PROFI.ORDER_SEARCH.TOOLTIP.REMOVE_ORDER' | translate"
                                mat-icon-button
                            >
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <p [innerHtml]="'ADMIN_PROFI.ORDER_SEARCH.MANUAL_HINT' | translate" class="order-hint mt-2"></p>
            </form>
            <div class="d-flex justify-content-end my-2">
                <button
                    (click)="openOrderSearchDialog()"
                    [disabled]="orderForm.pending || orderForm.invalid"
                    class="me-2"
                    color="primary"
                    mat-flat-button
                >
                    <!--    order search      -->
                    {{ 'ADMIN_PROFI.ORDER_SEARCH.BUTTON.OPEN_ORDER_SEARCH_DIALOG' | translate }}
                </button>
                <button
                    (click)="openMaterialSelectionDialog()"
                    [disabled]="
                        orderNumbersFormArray.length === 0 ||
                        !orderNumbersFormArray.controls[0].value ||
                        this.loading ||
                        orderNumbersFormArray.pending ||
                        orderForm.invalid
                    "
                    color="primary"
                    mat-flat-button
                >
                    <!--  Component selection    -->
                    {{ 'ADMIN_PROFI.ORDER_SEARCH.BUTTON.OPEN_COMPONENT_SELECTION_DIALOG' | translate }}
                </button>
            </div>
        </ng-container>
        <div *ngIf="ordersLoading" class="mt-2 px-3">
            <ngx-skeleton-loader
                [animation]="'progress-dark'"
                [appearance]="'line'"
                [count]="1"
                [theme]="{ width: '100%', height: '33px', 'margin-bottom': '16px' }"
            >
            </ngx-skeleton-loader>
        </div>
    </mat-card-content>
</mat-card>
