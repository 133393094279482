import { Component } from '@angular/core';
import { DownloadService } from '../../services/download.service';

@Component({
    selector: 'app-service-navigation',
    templateUrl: './service-navigation.component.html',
    styleUrls: ['./service-navigation.component.scss'],
})
export class ServiceNavigationComponent {
    constructor(public downloadService: DownloadService) {}
}
