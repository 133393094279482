import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TranslationConfigService {
    readonly defaultLanguage = 'de-DE';
    readonly supportedLanguages = [
        'de-DE', // German
    ];

    private currentLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(environment.app.defaultLanguage);

    constructor(private translate: TranslateService) {}

    setLanguage(language?: string): Observable<unknown> {
        return new Observable((subscriber) => {
            const supportedLanguage = this.getSupportedLanguage(language ? language : this.defaultLanguage);

            this.currentLanguage$.next(supportedLanguage);
            this.translate.setDefaultLang(this.defaultLanguage);
            this.translate.use(supportedLanguage).subscribe({
                next: () => subscriber.complete(),
                error: () => subscriber.complete(),
            });
        });
    }

    public getLanguage(): string {
        return this.currentLanguage$.value;
    }

    private getSupportedLanguage(language: string): string {
        const supportedLanguage = this.supportedLanguages.find((l) =>
            language.toLocaleLowerCase().startsWith(l.toLocaleLowerCase())
        );

        return supportedLanguage ? supportedLanguage : this.defaultLanguage;
    }
}
