<div class="service-navigation">
    <ul>
        <li>
            <a [href]="this.downloadService.getDownloadUrl('Adminprofi_Checkliste_012023.pdf', true)" target="_blank">{{
                'ADMIN_PROFI.SERVICE_NAVIGATION.BUTTON.CHECKLIST' | translate
            }}</a>
        </li>
        <li>
            <a [href]="this.downloadService.getDownloadUrl('Adminprofi_Prozessflyer.pdf', true)" target="_blank">{{
                'ADMIN_PROFI.SERVICE_NAVIGATION.BUTTON.PROCESS' | translate
            }}</a>
        </li>
        <li>
            <a routerLink="faq" target="_blank">{{ 'ADMIN_PROFI.SERVICE_NAVIGATION.BUTTON.FAQ' | translate }}</a>
        </li>
    </ul>
</div>
