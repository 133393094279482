import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private http: HttpClient) {}

    public deleteConfigurationAttachment(configurationId: string, attachmentId: string): Observable<boolean> {
        return this.http
            .delete(
                `${environment.apiUrl}backend/admin-profi/v1/configurations/${configurationId}/attachments/${attachmentId}`
            )
            .pipe(map(() => true));
    }

    public notifyAttachmentUpload(configurationId: string, sectionId: string) {
        return this.http
            .post(`${environment.apiUrl}backend/admin-profi/v1/configurations/${configurationId}/attachments/notify`, {
                sectionId: sectionId,
            })
            .pipe(map(() => true));
    }
}
