<mat-toolbar class="navigation-row px-5 justify-content-center">
    <div class="container-fluid d-flex flex-row justify-content-between ps-1 pe-0">
        <div class="d-flex flex-row align-items-center d-lg-none">
            <button
                mat-icon-button
                class="mr-3"
                (click)="menuButtonPressed()"
                *ngIf="!identityService.invalidUser$.value"
            >
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <div class="d-flex align-items-center">
            <a routerLink="/">
                <img
                    src="../../../../../assets/img/Viessmann_VAS_Adminprofi_RGB_Vitorange.svg"
                    alt="Viessmann Logo"
                    class="logo"
                />
            </a>
            <div class="flex-row align-items-center d-lg-flex d-none" *ngIf="!identityService.invalidUser$.value">
                <a class="ps-3" [href]="'ADMIN_PROFI.NAVIGATION.LINK.SERVICE' | translate">{{
                    'ADMIN_PROFI.NAVIGATION.TEXT.SERVICE' | translate
                }}</a>
                <a class="px-3" [href]="'ADMIN_PROFI.NAVIGATION.LINK.FAQ' | translate">{{
                    'ADMIN_PROFI.NAVIGATION.TEXT.FAQ' | translate
                }}</a>
                <a [href]="'ADMIN_PROFI.NAVIGATION.LINK.ABOUT_US' | translate">{{
                    'ADMIN_PROFI.NAVIGATION.TEXT.ABOUT_US' | translate
                }}</a>
                <a class="ps-3" routerLink="/">{{ 'ADMIN_PROFI.NAVIGATION.TEXT.REQUESTS' | translate }}</a>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center">
            <vih-login [lang]="translationConfigService.getLanguage()"> </vih-login>
        </div>
    </div>
</mat-toolbar>
