import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InstanaErrorHandler } from './instana-error-handler';
import { ConfigurationModule } from './modules/configuration/configuration.module';
import { FooterModule } from './modules/footer/footer.module';
import { ForbiddenComponent } from './modules/forbidden/forbidden.component';
import { MaintenanceModule } from './modules/maintenance/maintenance.module';
import { NavigationModule } from './modules/navigation/navigation.module';
import { ServiceNavigationModule } from './modules/service-navigation/service-navigation.module';
import { AuthService } from './services/auth.service';
import { HttpInterceptorService } from './services/http.interceptor';
import { TranslationConfigService } from './services/translation-config.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DswFooterComponent } from '@vi-succ/design-system-web';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function createTranslateLoader(http: HttpBackend) {
    return new MultiTranslateHttpLoader(http, [
        {
            prefix: `${environment.http.translationBaseUrl}phraseapp-proxy/projects/c7f60f0e0441e61f09662e8b3a921104/locales/`,
            suffix: '/download?file_format=simple_json',
        },
        {
            prefix: `${environment.http.translationBaseUrl}phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/`,
            suffix: '/download?file_format=simple_json',
        },
    ]);
}

const matModules = [
    MatDialogModule,
    MatSnackBarModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatSidenavModule,
];

@NgModule({
    declarations: [AppComponent, ForbiddenComponent],
    imports: [
        ...matModules,
        ConfigurationModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpBackend],
            },
        }),
        NavigationModule,
        FooterModule,
        MaintenanceModule,
        ServiceNavigationModule,
        DswFooterComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (translate: TranslationConfigService) => () => translate.setLanguage(),
            deps: [TranslationConfigService],
            multi: true,
        },
        AuthService,
        {
            provide: ErrorHandler,
            useClass: InstanaErrorHandler,
        },
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
