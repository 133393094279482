import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../../guards/auth.guard';
import { pendingChangesGuard } from '../../guards/change.guard';
import { ConfigurationComponent } from './configuration.component';

const routes: Routes = [
    {
        path: 'new',
        loadChildren: () => import('./modules/landingpage/landing-page.module').then((m) => m.LandingPageModule),
        canActivate: [authGuard],
        pathMatch: 'full',
    },
    {
        path: 'new/:model',
        component: ConfigurationComponent,
        canActivate: [authGuard],
        pathMatch: 'full',
    },
    {
        path: ':id/project/:project',
        component: ConfigurationComponent,
        canActivate: [authGuard],
        pathMatch: 'full',
        canDeactivate: [pendingChangesGuard],
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ConfigurationRoutingModule {}
