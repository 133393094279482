import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
    ConfigurationDetails,
    ConfigurationService,
    ConfigurationStatus,
} from '../../configuration/services/configuration.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationsTableService {
    baseUrl = environment.http.baseUrl;
    totalCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private configService: ConfigurationService
    ) {}

    searchConfigurations(options?: SearchParams): Observable<ConfigurationSearchResult> {
        return this.http
            .post<ConfigurationSearchResult>(
                `${this.baseUrl}configurations/search`,
                {
                    search: options?.search,
                    fields: options?.fields,
                },
                {
                    params: options?.pagination,
                }
            )
            .pipe(
                map((searchResult) => {
                    return {
                        results: searchResult.results.map((result) => {
                            const isEplan = result.model === 'E-Plan';
                            const systemDetails: SystemDetails = isEplan ? ePlanSystemDetails : adminProfiSystemDetails;
                            const projectDetails = this.getProjectDetails(result, result.model === 'E-Plan');
                            return {
                                ...result,
                                fullName: this.configService.getFullName(result),
                                street:
                                    this.configService.getPropertyFromResponse(result, adminProfiOperator.street) ||
                                    this.configService.getPropertyFromResponse(result, systemDetails.street) ||
                                    '',
                                projectType: projectDetails.projectType,
                                selectedEnergySources: projectDetails.sources,
                                status: result.status || ConfigurationStatus.ORDER_ENTRY,
                                complete: result.complete || !!result.completeDate,
                                completeDate: moment(result.completeDate, 'YYYY.MM.DD').toISOString(),
                                createdAt: moment(result.createdAt, 'YYYY.MM.DD').toISOString(),
                            };
                        }),
                        pagination: searchResult.pagination,
                    };
                })
            );
    }

    public getProjectDetails(
        results: ConfigurationDetails,
        isEplan?: boolean
    ): { projectType: string; sources: string[] } {
        const energySourcesVariables = isEplan ? ePlanEnergySources : adminProfiEnergySources;
        const energyAssignments = {
            PV: this.configService.getPropertyFromResponse(results, energySourcesVariables.PV) === 'True',
            SP: this.configService.getPropertyFromResponse(results, energySourcesVariables.SP) === 'True',
            BZ: this.configService.getPropertyFromResponse(results, energySourcesVariables.BZ) === 'True',
            WB: this.configService.getPropertyFromResponse(results, energySourcesVariables.WB) === 'True',
            HP: this.configService.getPropertyFromResponse(results, energySourcesVariables.HP) === 'True',
        };

        const sources = Object.entries(energyAssignments)
            .filter(([key, value]) => {
                return key && value;
            })
            .map(([key]) => key);

        const projectType =
            <string>(
                this.translate.instant(
                    `ADMIN_PROFI.OVERVIEW.TABLE.PROJECT_TYPE.LABEL.${
                        this.configService.getPropertyFromResponse(results, 'LandingPage.ServiceName') || sources.length
                    }`
                )
            ) || '-';

        return { projectType, sources };
    }

    deleteConfiguration(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}configurations/${id}`);
    }

    setTotalCount(count: number): void {
        this.totalCount$.next(count);
    }

    getTotalCount(): number {
        return this.totalCount$.value;
    }

    updateComment(id: string, updatedComment?: string) {
        return this.http.put(`${this.baseUrl}configurations/${id}`, { comment: updatedComment });
    }
}

export interface ConfigurationSearchResult {
    results: ConfigurationDetails[];
    pagination: Pagination;
}

interface Pagination {
    totalPages: number;
    totalCount: number;
    limit: number;
    page: number;
}

export interface SearchParams {
    fields: {
        [key: string]: string;
    };
    pagination: {
        limit: number;
        page: number;
    };
    search: {
        variableName: string;
        valueName: string;
    }[][];
}

export interface EnergySources {
    PV: string;
    SP: string;
    BZ: string;
    WB: string;
    HP: string;
}

export interface SystemDetails {
    firstName: string;
    lastName: string;
    mail: string;
    street: string;
    city: string;
}

export const adminProfiOperator: SystemDetails = {
    firstName: 'PersonalData.SystemOperator.FirstName',
    lastName: 'PersonalData.SystemOperator.LastName',
    mail: 'PersonalData.SystemOperator.EMailAddress',
    street: 'PersonalData.SystemOperator.Street',
    city: 'PersonalData.SystemOperator.City',
};

export const adminProfiSystemDetails: SystemDetails = {
    firstName: 'PersonalData.SystemAddress.FirstName',
    lastName: 'PersonalData.SystemAddress.LastName',
    mail: 'PersonalData.SystemAddress.EMailAddress',
    street: 'PersonalData.SystemAddress.Street',
    city: 'PersonalData.SystemAddress.City',
};

export const ePlanSystemDetails: SystemDetails = {
    firstName: 'EPlan.SystemAddress.FirstName',
    lastName: 'EPlan.SystemAddress.LastName',
    mail: 'EPlan.SystemAddress.EMailAddress',
    street: 'EPlan.SystemAddress.Street',
    city: 'EPlan.SystemAddress.City',
};

const adminProfiEnergySources: EnergySources = {
    PV: 'SystemsToBeRegistered.General.PVSystem_Checkbox',
    SP: 'SystemsToBeRegistered.General.BatteryStorage_Checkbox',
    BZ: 'SystemsToBeRegistered.General.FuelCell_Checkbox',
    WB: 'SystemsToBeRegistered.General.Wallbox_Checkbox',
    HP: 'SystemsToBeRegistered.General.HeatPump_Checkbox',
};

const ePlanEnergySources: EnergySources = {
    PV: 'EPlan.PlannedSystemComponents.PVSystem_Checkbox',
    SP: 'EPlan.PlannedSystemComponents.BatteryStorage_Checkbox',
    BZ: 'EPlan.PlannedSystemComponents.FuelCell_Checkbox',
    WB: 'EPlan.PlannedSystemComponents.Wallbox_Checkbox',
    HP: 'EPlan.PlannedSystemComponents.HeatPump_Checkbox,',
};
