<div *ngIf="visible" class="maintenance flex-column">
    <div *ngIf="partialOutage" class="d-flex justify-content-center align-items-center">
        <mat-icon>offline_bolt</mat-icon>
        <span [innerHTML]="'ADMIN_PROFI.MAINTENANCE.MESSAGE.PARTIAL' | translate"></span>
    </div>
    <div *ngIf="maintenance" class="d-flex justify-content-center align-items-center">
        <mat-icon>construction</mat-icon>
        <span
            *ngIf="maintenance"
            [innerHTML]="
                'ADMIN_PROFI.MAINTENANCE.MESSAGE.PLANNED'
                    | translate
                        : {
                              from: maintenance.start_date | date : dateFormat,
                              until: maintenance.end_date | date : dateFormat
                          }
            "
        ></span>
    </div>
</div>
