import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Quest, QuestCheckResult, QuestPartChange, QuestQuestion } from '@vi/quest';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    defaultIfEmpty,
    exhaustMap,
    forkJoin,
    map,
    Observable,
    of,
    skip,
    Subject,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ComponentCanDeactivate } from '../../guards/change.guard';
import { IdentityService } from '../../services/identity.service';
import { InstanaService } from '../../services/instana.service';
import { ConfigurationsTableService } from '../overview/services/configurations-table.service';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { CheckEvent } from './modules/landingpage/landing-page.component';
import { ConfigitQuestAdapterService } from './services/configit-quest-adapter.service';
import { ConfigitAssignment } from './services/configit.model';
import { ConfigAttachment, ConfigurationDetails, ConfigurationService } from './services/configuration.service';
import { MandateService } from './services/mandate.service';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    public quest$: Subject<Quest> = new Subject<Quest>();
    destroy$: Subject<boolean> = new Subject<boolean>();
    public modelName = environment.quest.adminProfiModel;
    status: 'Pending' | 'Default' | 'Error' | 'Submitting' = 'Default';
    configId = '';
    projectId?: string;
    userName = '';
    save$ = new BehaviorSubject<boolean>(true);
    isEplan = false;
    isAdmin = false;

    saveErrorMsg = '';
    saveButtondisabled = true;

    showMandateBtn = false;
    showConfirmationHint = false;
    showThanksScreen = false;
    isCompleted = false;
    projectType: string = '';

    showNotCompletedHint = false;

    attachments: ConfigAttachment[] = [];

    constructor(
        private questAdapterService: ConfigitQuestAdapterService,
        private snackbar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private identityService: IdentityService,
        public configurationService: ConfigurationService,
        private location: Location,
        private translate: TranslateService,
        private mandateService: MandateService,
        private tableService: ConfigurationsTableService,
        private instanaService: InstanaService,
        private dialog: MatDialog
    ) {}

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return this.saveButtondisabled;
    }

    ngOnInit(): void {
        this.status = 'Pending';
        this.getUser();
        this.initQuestionnaire();
        this.translate
            .get('ADMIN_PROFI.CONFIGURATION.SNACKBAR.SAVE_ERROR')
            .subscribe((errorMsg) => (this.saveErrorMsg = errorMsg));
    }

    private getUser(): void {
        forkJoin([this.identityService.user$, this.identityService.isAdmin$]).subscribe(([user, isAdmin]) => {
            this.userName = user.loginId;
            this.isAdmin = isAdmin;
        });
    }

    check(changed: CheckEvent) {
        this.questAdapterService
            .check(changed)
            .pipe(
                map((result: QuestCheckResult) => result.model),
                switchMap((model) => this.checkForExternalUpdates(model))
            )
            .subscribe({
                next: () => {
                    if (this.isAdmin) {
                        this.saveButtondisabled = false;
                    } else {
                        this.saveConfig();
                    }
                },
                error: () => {
                    this.onError(this.translate.instant('ADMIN_PROFI.COMMON.SNACK_BAR.UPDATE_VALUES.ERROR'));
                },
            });
    }

    setModel(model?: Quest, error?: boolean) {
        if (error) {
            this.onError(this.translate.instant('ADMIN_PROFI.COMMON.SNACK_BAR.UPDATE_VALUES.ERROR'));
        }
        this.prepareFileHandling(model);
        this.status = 'Default';
        if (model) {
            this.quest$.next(model);
        }
    }

    updateWithExternalAssignments(assignments: ConfigitAssignment[]): void {
        this.status = 'Pending';

        this.questAdapterService
            .get(this.modelName, assignments)
            .pipe(map((model) => this.configurationService.addPowerGridInfoToQuest(model)))
            .subscribe((model) => {
                this.configurationService.quest = model;
                this.setModel(model);
                this.saveConfig();
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.configId = '';
        this.configurationService.quest = undefined;
        this.configurationService.landingPageAssignments = undefined;
    }

    private initQuestionnaire(): void {
        this.configurationService.resetServiceObs();
        this.route.params
            .pipe(
                switchMap((params) => {
                    this.configId = params['id'] || '';
                    this.projectId = params['project'] || '';

                    return this.configId
                        ? this.configurationService.getConfig(this.configId)
                        : this.configurationService.createConfiguration(
                              this.configurationService.getAssignments(true),
                              params['model'] === 'EPlan' ? 'E-Plan' : 'AdminProfi'
                          );
                }),
                switchMap((configuration) => {
                    this.initComponent(configuration);
                    return this.questAdapterService.get(this.modelName, configuration.data);
                }),
                switchMap((model) => {
                    this.configurationService.quest = model;
                    return this.checkForExternalUpdates(model);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe({
                next: (model) => {
                    this.configurationService.quest = model;
                    this.startAutoSaveInterval();
                },
                error: (err) => {
                    this.router.navigate(['error'], { queryParams: { status: err?.status } });
                },
            });
    }

    private initComponent(configuration: ConfigurationDetails) {
        this.isEplan = configuration?.model === 'E-Plan';
        this.modelName = this.isEplan ? environment.quest.ePlanModel : environment.quest.adminProfiModel;
        if (configuration._id !== this.configId) {
            this.configId = configuration._id;
            this.projectId = configuration.projectId;
            this.router.navigate(['configuration', this.configId, 'project', configuration?.projectId]);
        }
        this.attachments = configuration.attachments || [];
        this.isCompleted = !!configuration.complete;
        if (configuration.complete && !this.isAdmin) {
            this.configurationService.setReadOnly();
        }
    }

    private prepareFileHandling(model?: Quest): Quest | undefined {
        // overwrite model for file handling
        model?.parts.forEach((part) => {
            const questions: QuestQuestion[] =
                part.questions ||
                (part.groups || [])
                    .reduce(
                        (prev: any[], curr) => [
                            ...prev,
                            ...(Array.isArray(curr.questions) ? curr.questions : [curr.questions]),
                        ],
                        []
                    )
                    .filter((question) => question.type === 'file');

            // Set payload and overwrite upload Url
            questions.forEach((question) => {
                if (question.upload) {
                    question.upload.data = {
                        questionId: question.id,
                    };
                    question.upload.url = environment.file.uploadUrl.replace('{id}', this.configId || '');
                }

                // Find the right question for each attachment and overwrite download url
                this.attachments.forEach((attachment) => {
                    if (question.id === attachment.questionId) {
                        question.template = {
                            url: environment.file.downloadUrl
                                .replace('{id}', this.configId || '')
                                .replace('{attachmentId}', attachment.attachmentId),
                        };
                    }
                });
            });
        });

        return model;
    }

    public checkIfComplete(submittedModel?: Quest | Promise<Quest>) {
        const model: Quest = <Quest>submittedModel;
        const complete: boolean = model?.original?.configuration?.complete;
        if (complete) {
            this.showConfirmationHint = true;
        } else {
            this.saveConfig();
            this.showNotCompletedHint = true;
        }
    }

    public submit() {
        this.status = 'Submitting';
        this.showThanksScreen = true;
        this.configurationService
            .updateConfiguration(this.configId, {
                data: this.configurationService.getAssignments(),
                complete: true,
            })
            .subscribe({
                next: () => {
                    this.status = 'Default';
                    this.showThanksScreen = true;
                    this.showConfirmationHint = false;
                },
                error: () => this.onError(this.translate.instant('ADMIN_PROFI.CONFIGURATION.SNACKBAR.SUBMIT_ERROR')),
            });
    }

    handlePartChange(partChange: QuestPartChange): void {
        this.showMandateBtn = this.configurationService.quest?.parts[partChange.index].id === 'DownloadPowerOfAttorney';
        this.showNotCompletedHint = false;
        if (this.isCompleted) {
            return;
        }
        this.saveConfig();
    }

    private startAutoSaveInterval(): void {
        this.save$
            .pipe(
                skip(1),
                filter(() => !this.configurationService.isReadOnly$.value),
                debounceTime(2500),
                exhaustMap(() => {
                    return this.configurationService.updateConfiguration(this.configId, {
                        data: this.configurationService.getAssignments(),
                    });
                }),
                takeUntil(this.destroy$)
            )
            .subscribe({
                next: (config) => {
                    this.attachments = config.attachments;
                    this.prepareFileHandling(this.configurationService.quest);
                },
                error: () => this.onError(this.saveErrorMsg),
            });
    }

    public generateMandate(): void {
        const mandateInfo = this.configurationService.getMandateInformation(
            this.userName || this.modelName,
            this.projectId || this.configId
        );

        this.mandateService.generateMandate(mandateInfo).subscribe({
            next: (data) => {
                this.downloadMandate(data);
            },
            error: async (err) => {
                this.downloadMandate();
                if (err instanceof HttpErrorResponse) {
                    this.instanaService.reportError('Generate Mandate Error', JSON.parse(await err.error.text()));
                }
            },
        });
    }

    private downloadMandate(mandate?: Blob) {
        const a = document.createElement('a');
        let blob;

        if (mandate) {
            blob = new Blob([mandate], { type: 'application/pdf' });
        }

        // Download generated pre-filled PDF mandate if successfully generated and download default mandate PDF if not
        const url = blob
            ? window.URL.createObjectURL(blob)
            : this.translate.instant('ADMIN_PROFI.DOWNLOAD.MANDATE_PDF.FILE_URL');

        a.href = url;
        a.target = '_blank';
        a.download = this.translate.instant('ADMIN_PROFI.DOWNLOAD.MANDATE_PDF.FILE_NAME');
        a.click();
        window.URL.revokeObjectURL(url);
    }

    private onError(errorMsg: string) {
        this.snackbar.open(errorMsg, '', {
            panelClass: ['save-error-snackbar'],
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    public manuallySaveConfig() {
        this.save$.next(true);
        this.saveButtondisabled = true;
        this.snackbar.open(this.translate.instant('ADMIN_PROFI.CONFIGURATION.ADMIN_SAVE_SUCCESS'), '', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
        });
    }

    private saveConfig(): void {
        if (!this.isAdmin) {
            this.save$.next(true);
        }
    }

    private checkForExternalUpdates(model?: Quest) {
        return combineLatest([
            this.configurationService.checkForInvoicePrefillment(this.isEplan).pipe(defaultIfEmpty([])),
            this.configurationService.checkModelForSystemAddress().pipe(defaultIfEmpty([])),
        ]).pipe(
            switchMap(([invoiceAssignments, gridAssignments]) => {
                if (invoiceAssignments.length || gridAssignments.length) {
                    // Repeat getFromExistingConfiguration if you want to pre-fill the questionnaire
                    // with the invoice recipient or power grid operator info.
                    return this.questAdapterService
                        .get(this.modelName, [
                            ...this.configurationService.quest?.original.assignments.filter(
                                (assigment: { variableName: string }) =>
                                    !assigment.variableName.includes('GridOperator')
                            ),
                            ...invoiceAssignments,
                            ...gridAssignments,
                        ])
                        .pipe(map((model) => this.configurationService.addPowerGridInfoToQuest(model)));
                } else {
                    return of(this.configurationService.addPowerGridInfoToQuest(model));
                }
            }),
            tap((model) => {
                this.configurationService.quest = model;
                if (!this.isCompleted) {
                    this.saveConfig();
                }
                this.setModel(model);
            })
        );
    }

    openVideoDialog() {
        this.dialog.open(VideoDialogComponent, {
            width: '1080px',
        });
    }
}
