import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { adminAuthGuard } from './guards/admin-auth.guard';
import { authGuard } from './guards/auth.guard';
import { maintenanceGuard } from './guards/maintenance.guard';
import { MaintenanceComponent } from './modules/maintenance/maintenance.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/overview/overview.module').then((m) => m.OverviewModule),
        canActivate: [authGuard, maintenanceGuard],
        pathMatch: 'full',
    },
    {
        path: 'configuration',
        loadChildren: () => import('./modules/configuration/configuration.module').then((m) => m.ConfigurationModule),
        canActivate: [authGuard, maintenanceGuard],
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin-view/admin-view.module').then((m) => m.AdminViewModule),
        canActivate: [adminAuthGuard, maintenanceGuard],
    },
    {
        path: 'forbidden',
        loadChildren: () => import('./modules/forbidden/forbidden.module').then((m) => m.ForbiddenModule),
    },
    {
        path: 'faq',
        loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FaqModule),
        canActivate: [adminAuthGuard, maintenanceGuard],
    },
    {
        path: 'error',
        loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
        canActivate: [authGuard, maintenanceGuard],
    },
    {
        path: 'incorrect-customer-data',
        loadChildren: () =>
            import('./modules/incorrect-customer-data/incorrect-customer-data.module').then(
                (m) => m.IncorrectCustomerDataModule
            ),
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
