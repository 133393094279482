import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
    canDeactivate(): boolean | Observable<boolean>;
}

export const pendingChangesGuard: CanDeactivateFn<ComponentCanDeactivate> = (component) => {
    const translate: TranslateService = inject(TranslateService);
    return component.canDeactivate() ? true : confirm(translate.instant('ADMIN_PROFI.CONFIGURATION.DIALOG.SAVE_ERROR'));
};
