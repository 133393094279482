import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormArray,
    FormBuilder,
    FormControl,
    ValidationErrors,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
    BehaviorSubject,
    debounceTime,
    defaultIfEmpty,
    distinctUntilChanged,
    forkJoin,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    takeUntil,
} from 'rxjs';
import { catchError, filter, first } from 'rxjs/operators';
import { ConfigitAssignment } from '../../services/configit.model';
import { ConfigurationService } from '../../services/configuration.service';
import { Order, OrderItem, OrderService } from '../../services/order.service';
import { ComponentSelectionDialogComponent } from './component-selection-dialog/component-selection-dialog.component';
import { OrderSearchDialogComponent } from './order-search-dialog/order-search-dialog.component';

@Component({
    selector: 'app-order-search',
    templateUrl: './order-search.component.html',
    styleUrls: ['./order-search.component.scss'],
})
export class OrderSearchComponent implements OnInit, OnDestroy {
    public loading = false;
    public ordersLoading = true;
    orderForm = this.fb.group({
        orderNumbers: this.fb.array([
            new FormControl<string | null | undefined>(
                '',
                [],
                this.debouncedValueValidatorFactory(1000, this.asyncValidator.bind(this)).bind(this)
            ),
        ]),
    });

    @Output() update = new EventEmitter<ConfigitAssignment[]>();
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public orderService: OrderService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private configurationService: ConfigurationService,
        private route: ActivatedRoute
    ) {}

    get orderNumbersFormArray() {
        return <FormArray<FormControl<string | null | undefined>>>this.orderForm.get('orderNumbers');
    }

    ngOnInit(): void {
        this.orderForm.statusChanges
            .pipe(
                filter((status) => status === 'VALID'),
                takeUntil(this.unsubscribe$)
            )
            .pipe(
                switchMap(() => {
                    this.loading = true;
                    return forkJoin(
                        this.orderService.orders.map((order) => this.orderService.getItemDetails(order.orderItems))
                    ).pipe(defaultIfEmpty([]));
                })
            )
            .subscribe((data) => {
                this.loading = false;
                this.orderService.upsertProductDetails(data.flat());
            });

        this.route.params
            .pipe(
                switchMap((params) => {
                    return params['id']
                        ? this.configurationService.getConfig(params['id']).pipe(map((config) => config.orders))
                        : of([]);
                }),
                switchMap((orderNumbers) =>
                    orderNumbers.length
                        ? forkJoin(orderNumbers.map((number) => this.orderService.getOrderItems(number)))
                        : of([])
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((orderItems: OrderItem[][]) => {
                this.ordersLoading = false;
                this.updateOrderForm(this.orderService.orders, !!orderItems.flat().length);
            });
    }

    addRow(orderNumber?: string) {
        this.orderNumbersFormArray.push(
            new FormControl<string | undefined>(
                orderNumber,
                [],
                // @ts-ignore
                [this.debouncedValueValidatorFactory(1000, this.asyncValidator.bind(this)).bind(this)]
            )
        );
    }

    deleteRow(index: number) {
        if (this.orderNumbersFormArray.controls[index].valid) {
            this.orderService.removeOrder(this.orderNumbersFormArray.controls[index].value || '');
        }

        this.orderNumbersFormArray.controls.length !== 1
            ? this.orderNumbersFormArray.removeAt(index)
            : this.orderNumbersFormArray.at(index).reset();
    }

    deleteOrder(orderId: string) {
        this.orderService.removeOrder(orderId);

        const index = this.orderNumbersFormArray.controls.findIndex((control) => control.value === orderId);

        if (index !== -1) {
            this.orderNumbersFormArray.removeAt(index);
        }
    }

    openMaterialSelectionDialog() {
        this.markSelectedOrders();
        const dialogRef = this.dialog.open(ComponentSelectionDialogComponent, {
            data: this.orderService.orders
                .filter((order) => order.selected)
                .map((order) => order.orderItems)
                .flat(),
        });

        dialogRef
            .afterClosed()
            .pipe(filter(Boolean))
            .subscribe({
                next: (assignments: ConfigitAssignment[]) => {
                    this.update.emit(assignments);
                },
            });
    }

    openOrderSearchDialog() {
        this.markSelectedOrders();
        const dialogRef = this.dialog.open(OrderSearchDialogComponent, {
            width: '700px',
            disableClose: true,
        });

        dialogRef
            .afterClosed()
            .pipe(filter(Boolean))
            .subscribe({
                next: (selectedOrders) => {
                    this.updateOrderForm(selectedOrders, !!selectedOrders.length);
                },
            });
    }

    private markSelectedOrders() {
        // Mark all manually added orders as selected and disabled for the dialog
        this.orderService.orders = this.orderService.orders.map((order) => {
            const markSelectedAndDisabled = this.orderNumbersFormArray.value.includes(order.orderNumber);
            return { ...order, selected: markSelectedAndDisabled, disabled: markSelectedAndDisabled };
        });
    }

    private updateOrderForm(orders: Order[], removeEmptyFields?: boolean) {
        // Remove all empty forms if order numbers exist
        if (removeEmptyFields) {
            this.orderNumbersFormArray.controls.forEach((control, index) => {
                if (!control.value) {
                    this.orderNumbersFormArray.removeAt(index);
                }
            });
        }
        // Delete all form fields / orders which are not selected in the dialog
        this.orderService.orders
            .filter((order) => !orders.map((order) => order.orderNumber).includes(order.orderNumber))
            .forEach((order) => {
                this.deleteOrder(order.orderNumber);
            });

        // Add a new form field for each new order number
        orders.forEach((order) => {
            if (!this.orderNumbersFormArray.value.includes(order.orderNumber)) {
                this.addRow(order.orderNumber);
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.orderService.resetOrders();
    }

    private debouncedValueValidatorFactory(
        miliseconds: number,
        validator: (inputValue: string) => Observable<any>
    ): AsyncValidatorFn {
        const debouncedSubject = new BehaviorSubject('');
        const debouncedObservable = debouncedSubject.pipe(debounceTime(miliseconds), distinctUntilChanged());
        return (control: AbstractControl) => {
            if (!control.valueChanges || control.pristine) {
                return of(null);
            }
            debouncedSubject.next(control.value);
            return debouncedObservable.pipe(first(), filter(Boolean), switchMap(validator));
        };
    }

    private asyncValidator(value: string): Observable<ValidationErrors | null> {
        if (this.orderService.orders.find((order) => order.orderNumber === value.trim())) {
            return of({ alreadyInUse: true });
        }
        return this.orderService.getOrderItems(value).pipe(
            map((items) => {
                return !items.length ? { notRelevant: true } : null;
            }),
            catchError(() => {
                return of({ notFound: true });
            })
        );
    }
}
