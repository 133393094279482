<mat-sidenav-container class="bg-grey-light h-100">
    <mat-sidenav #sidenav class="p-3 overflow-hidden bg-grey-dark">
        <app-secondary-navigation (closeIconPressed)="toggleSideNav()"></app-secondary-navigation>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="h-100 d-flex flex-column">
            <div class="flex-grow-0">
                <app-navigation (menuButtonClick)="toggleSideNav()"></app-navigation>
            </div>
            <div class="flex-grow-1 bg-grey-light pb-5 content">
                <app-service-navigation class="d-none d-sm-block"></app-service-navigation>
                <app-maintenance-message></app-maintenance-message>
                <router-outlet></router-outlet>
            </div>
            <div class="flex-grow-0">
                <dsw-footer locale="de-DE" cookies></dsw-footer>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
