import { Component, EventEmitter, Output } from '@angular/core';
import { IdentityService } from '../../../../services/identity.service';
import { TranslationConfigService } from '../../../../services/translation-config.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
    @Output() menuButtonClick = new EventEmitter();
    constructor(public translationConfigService: TranslationConfigService, public identityService: IdentityService) {}

    menuButtonPressed() {
        this.menuButtonClick.emit();
    }
}
