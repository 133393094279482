import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentValidationService } from '../../../services/component-validation.service';
import { OrderItem } from '../../../services/order.service';

@Component({
    selector: 'app-component-selection-dialog',
    templateUrl: './component-selection-dialog.component.html',
    styleUrls: ['./component-selection-dialog.component.scss'],
})
export class ComponentSelectionDialogComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<ComponentSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OrderItem[],
        private componentValidation: ComponentValidationService
    ) {}

    selectedItems: OrderItem[] = [];

    public readonly allowedNumberOfPvModules = 1;
    public readonly allowedNumberOfInverters = 10;
    public readonly allowedNumberOfStorages = 5;
    public readonly allowedNumberOfChargingStationsOrWallBoxes = 5;
    public readonly allowedNumberOfHeatPumps = 3;

    public selectedPvModules: OrderItem[] = [];
    public selectedInverters: OrderItem[] = [];
    public selectedStorages: OrderItem[] = [];
    public selectedChargingStationsOrWallBoxes: OrderItem[] = [];
    public selectedHeatPumps: OrderItem[] = [];

    trackByIdentity = (index: number, item: unknown) => item;

    ngOnInit() {
        this.data = this.componentValidation.filterRelevantOrderItems(this.data);
    }

    public select(selectedItem: OrderItem, index: number): void {
        this.data[index].selected = !this.data[index].selected;

        this.selectedItems = this.data.filter((item) => !!item.selected);

        this.disableRemainingComponents();
    }

    public closeDialog(): void {
        const externalAssignments = this.componentValidation.getExternalAssignments(this.selectedItems);

        externalAssignments ? this.dialogRef.close(externalAssignments) : this.dialogRef.close();
    }

    private disableRemainingComponents() {
        this.selectedPvModules = this.selectedItems.filter(
            (item) => item.selected && this.componentValidation.isPvModule(item)
        );

        this.selectedInverters = this.selectedItems.filter(
            (item) => item.selected && this.componentValidation.isInverter(item)
        );

        this.selectedStorages = this.selectedItems.filter(
            (item) => item.selected && this.componentValidation.isStorage(item)
        );

        this.selectedChargingStationsOrWallBoxes = this.selectedItems.filter(
            (item) => item.selected && this.componentValidation.isChargingStationOrWallBox(item)
        );

        this.selectedHeatPumps = this.selectedItems.filter(
            (item) => item.selected && this.componentValidation.isHeatPump(item)
        );

        this.data = this.data.map((item) => {
            if (this.componentValidation.isPvModule(item)) {
                return {
                    ...item,
                    disabled: !item.selected && this.selectedPvModules.length === this.allowedNumberOfPvModules,
                };
            }

            if (this.componentValidation.isInverter(item) && this.componentValidation.isStorage(item)) {
                return {
                    ...item,
                    disabled:
                        !item.selected &&
                        (this.selectedInverters.length === this.allowedNumberOfInverters ||
                            this.selectedStorages.length === this.allowedNumberOfStorages),
                };
            }

            if (this.componentValidation.isInverter(item)) {
                return {
                    ...item,
                    disabled: !item.selected && this.selectedInverters.length === this.allowedNumberOfInverters,
                };
            }

            if (this.componentValidation.isStorage(item)) {
                return {
                    ...item,
                    disabled: !item.selected && this.selectedStorages.length === this.allowedNumberOfStorages,
                };
            }

            if (this.componentValidation.isChargingStationOrWallBox(item)) {
                return {
                    ...item,
                    disabled:
                        !item.selected &&
                        this.selectedChargingStationsOrWallBoxes.length ===
                            this.allowedNumberOfChargingStationsOrWallBoxes,
                };
            }

            if (this.componentValidation.isHeatPump(item)) {
                return {
                    ...item,
                    disabled: !item.selected && this.selectedHeatPumps.length === this.allowedNumberOfHeatPumps,
                };
            }

            return item;
        });
    }
}
