import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IdentityService } from '../../../services/identity.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    private baseUrl = environment.http.baseUrl;
    private productDetails$: BehaviorSubject<ProductDetails[]> = new BehaviorSubject<ProductDetails[]>([]);
    private orderItems$: BehaviorSubject<OrderItem[]> = new BehaviorSubject<OrderItem[]>([]);
    private orders$: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
    private configurationOwnerCompany$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(private http: HttpClient, private identityService: IdentityService) {}

    get orders() {
        return this.orders$.value;
    }

    set orders(orders: Order[]) {
        this.orders$.next(orders);
    }

    get orderItems() {
        return this.orderItems$.value;
    }

    get configurationOwnerCompany() {
        return this.configurationOwnerCompany$.value;
    }

    set configurationOwnerCompany(customerNumber: string) {
        this.configurationOwnerCompany$.next(customerNumber);
    }

    public searchOrders(from: string, to: string): Observable<Order[]> {
        return this.getSalesDocuments(from, to).pipe(
            tap((orders) => {
                this.orders = [...this.orders.filter((order) => order.selected), ...orders].filter(
                    (order, index, originOrders) => {
                        return (
                            index ===
                            originOrders.findIndex((originOrder) => originOrder.orderNumber === order.orderNumber)
                        );
                    }
                );
            })
        );
    }

    public getOrderItems(orderId: string): Observable<OrderItem[]> {
        if (this.orders$.value.find((order) => order.orderNumber === orderId)) {
            return of(this.orders$.value.find((order) => order.orderNumber === orderId)?.orderItems || []);
        }

        return this.searchOrderDetails(orderId).pipe(
            tap((items) => {
                if (items.length) {
                    this.orders$.next([...this.orders, { orderNumber: orderId, orderItems: items }]);
                    this.orderItems$.next([...this.orderItems, ...items]);
                }
            }),
            catchError((err) => {
                if (err.status === 404) {
                    return throwError(err);
                }
                return of([]);
            })
        );
    }

    private searchOrderDetails(orderId: string): Observable<OrderItem[]> {
        return this.http.get<OrderItem[]>(`${this.baseUrl}orders/${orderId}`, {
            params: {
                language: 'de',
                country: 'DE',
                salesOrg: '0500',
            },
        });
    }

    getItemDetails(items: OrderItem[]): Observable<ProductDetails[]> {
        const materials: string[] = [];
        const details: ProductDetails[] = [];

        items.forEach((item) => {
            const detail = this.productDetails$.value.find((product) => product.partNumber === item.material);

            detail ? details.push(detail) : materials.push(item.material);
        });

        const emptyProductDetails: ProductDetails[] = [{ billOfMaterial: [], partNumber: '', technicalData: [] }];

        return materials.length > 0
            ? forkJoin({
                  productDetails: this.http
                      .post<ProductDetails[]>(
                          `${this.baseUrl}pim/product-data`,
                          {
                              products: materials,
                          },
                          {
                              params: {
                                  language: 'de',
                                  country: 'de',
                              },
                          }
                      )
                      .pipe(
                          tap((productDetails) => {
                              productDetails.forEach((detail) => {
                                  this.productDetails$.next([...this.productDetails$.value, detail]);
                              });
                          }),
                          first(),
                          // If PV or HP Planner throws an error when retrieving the product details,
                          // we should catch this as an "empty" product to avoid subsequent errors. The FE only filters out specific products anyway.
                          catchError(() => of(emptyProductDetails))
                      ),
                  localDetails: of(details),
              }).pipe(map(({ productDetails, localDetails }) => [...productDetails, ...localDetails]))
            : of(details);
    }

    upsertProductDetails(details: ProductDetails[]) {
        const updatedOrders = this.orders.map((order) => {
            return {
                ...order,
                orderItems: order.orderItems.map((item) => {
                    return {
                        ...item,
                        details: details.find((p) => p.partNumber === item.material),
                    };
                }),
            };
        });

        this.orders$.next(updatedOrders);
        this.orderItems$.next(this.orders.map((order) => order.orderItems).flat());
    }

    public resetOrders(): void {
        this.orders$.next([]);
        this.orderItems$.next([]);
    }

    removeOrder(orderNumber: string) {
        this.orders$.next(this.orders$.value.filter((order) => order.orderNumber !== orderNumber));
        this.orderItems$.next(this.orders$.value.map((order) => order.orderItems).flat());
    }

    private getSalesDocuments(from: string, to: string): Observable<Order[]> {
        return forkJoin([this.identityService.user$, this.identityService.isAdmin$]).pipe(
            switchMap(([user, isAdmin]) => {
                return this.http.get<SalesDocuments>(`${this.baseUrl}orders`, {
                    params: {
                        language: user.languageCode,
                        // Search only own orders for non admins and search the orders which belong to the owner company of the config for admin
                        // Use the own company id as fallback for admins if they create a config on their own
                        customer: isAdmin ? this.configurationOwnerCompany || user.company.id : user.company.id,
                        salesOrg: '0500',
                        country: 'DE',
                        from,
                        to,
                    },
                });
            }),
            map((salesDocument) =>
                salesDocument.salesDocuments.map((sDoc) => {
                    return {
                        orderNumber: sDoc.documentNumber,
                        customerPurchaseNumber: sDoc.customerPurchaseNumber,
                        orderItems: sDoc.items,
                        documentCreationDate: sDoc.documentCreationDate,
                    };
                })
            )
        );
    }
}

export interface Order {
    orderNumber: string;
    orderItems: OrderItem[];
    selected?: boolean;
    disabled?: boolean;
    customerPurchaseNumber?: string;
    documentCreationDate?: string;
}

export interface OrderItem {
    material: string;
    shortText: string;
    quantity: number;
    selected?: boolean;
    disabled?: boolean;
    details?: ProductDetails;
    deviceCategoryId: string;
    partType: 'PV' | 'HP';
}

export interface ProductDetails {
    billOfMaterial: {
        materialNumber: string;
        quantity: number;
        deviceCategoryId: string;
        deviceCategoryDesc: string;
    }[];
    technicalData: {
        materialNumber: string;
        key: string;
        keyDescription: string;
        value: string;
        valueDescription: string;
        unit: string;
        dataType: string;
    }[];
    partNumber: string;
}

export interface SalesDocuments {
    salesDocuments: SalesDocument[];
}

export interface SalesDocument {
    documentNumber: string;
    customerPurchaseNumber: string;
    items: OrderItem[];
    documentCreationDate: string;
}
