import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MaintenanceMessageComponent } from './maintenance-message/maintenance-message.component';
import { MaintenanceComponent } from './maintenance.component';

@NgModule({
    declarations: [MaintenanceComponent, MaintenanceMessageComponent],
    imports: [CommonModule, TranslateModule, MatButtonModule, MatIconModule],
    exports: [MaintenanceMessageComponent],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'l',
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'L',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
})
export class MaintenanceModule {}
