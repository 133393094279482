import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PowerGridInfoService {
    constructor(private http: HttpClient) {}

    getPowerGrids(address: Address): Observable<PowerGridInfo> {
        const params = {
            street: address.street,
            city: address.city,
            zip: address.zipCode,
        };

        return this.http.get<Grids>(`${environment.http.baseUrl}power-grids`, { params }).pipe(
            map((grid) => {
                return {
                    operator: grid.grids[0].operator,
                };
            })
        );
    }
}

export interface Address {
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
}

export interface Grids {
    grids: PowerGridInfo[];
}

export interface PowerGridInfo {
    operator: {
        name: string;
        street: string;
        zip: string;
        location: string;
        phone: string;
        fax: string;
        url: string;
        email: string;
        bdewId13v: string;
        bdewId13n: string;
    };
}
