import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Quest, QuestionAppearance, QuestionType, QuestQuestion } from '@vi/quest';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IdentityService, Me } from '../../../services/identity.service';
import {
    adminProfiOperator,
    adminProfiSystemDetails,
    ePlanSystemDetails,
    SystemDetails,
} from '../../overview/services/configurations-table.service';
import { Company, CompanyService } from './company.service';
import { ConfigitAssignment } from './configit.model';
import { MandateDetails } from './mandate.service';
import { OrderService } from './order.service';
import { Address, PowerGridInfo, PowerGridInfoService } from './power-grid-info.service';

enum System {
    STREET = 'PersonalData.SystemAddress.Street',
    HOUSE_NUMBER = 'PersonalData.SystemAddress.HouseNumber',
    ZIPCODE = 'PersonalData.SystemAddress.ZipCode',
    CITY = 'PersonalData.SystemAddress.City',
}

enum MandateSubscriber {
    NAME = 'DownloadPowerOfAttorney.Connectee.FirstName',
    SURNAME = 'DownloadPowerOfAttorney.Connectee.LastName',
    STREET = 'DownloadPowerOfAttorney.Connectee.Street',
    HOUSE_NUMBER = 'DownloadPowerOfAttorney.Connectee.HouseNumber',
    ZIP = 'DownloadPowerOfAttorney.Connectee.ZipCode',
    CITY = 'DownloadPowerOfAttorney.Connectee.City',
    BIRTHDAY = 'DownloadPowerOfAttorney.Connectee.BirthDate',
    BIRTHPLACE = 'DownloadPowerOfAttorney.Connectee.BirthPlace',
}

enum MandateConstructor {
    NAME = 'DownloadPowerOfAttorney.Installer.FirstName',
    SURNAME = 'DownloadPowerOfAttorney.Installer.LastName',
    STREET = 'DownloadPowerOfAttorney.Installer.Street',
    HOUSE_NUMBER = 'DownloadPowerOfAttorney.Installer.HouseNumber',
    ZIP = 'DownloadPowerOfAttorney.Installer.ZipCode',
    CITY = 'DownloadPowerOfAttorney.Installer.City',
    BIRTHDAY = 'DownloadPowerOfAttorney.Installer.BirthDate',
    BIRTHPLACE = 'DownloadPowerOfAttorney.Installer.BirthPlace',
}

export interface UpdateOrder {
    data?: ConfigitAssignment[];
    complete?: boolean;
    status?: ConfigurationStatus;
    commissioningDate?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    public lang: string = 'de-DE';
    private _quest?: Quest;
    private _salesAreaName: string | null | undefined;
    private _plant: string | null | undefined;
    readonly personalDataPartId = 'PersonalData';
    readonly systemAddressGroupId = 'PersonalData.SystemAddress';
    private address$ = new BehaviorSubject<Address | null>(null);
    private powerGridInfo$ = new BehaviorSubject<PowerGridInfo | null>(null);
    private baseUrl = environment.http.baseUrl;
    public landingPageAssignments?: ConfigitAssignment[];

    public isReadOnly$ = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private powerGridInfoService: PowerGridInfoService,
        private translate: TranslateService,
        private identityService: IdentityService,
        private companyService: CompanyService,
        private orderService: OrderService
    ) {}

    get quest(): Quest | undefined {
        return this._quest;
    }

    set quest(quest: Quest | undefined) {
        this._quest = quest;
    }

    get salesAreaName(): string | null {
        if (this._salesAreaName) {
            return this._salesAreaName;
        }

        switch (this.lang) {
            case 'de-DE':
                return 'Germany';
            default:
                return 'Germany';
        }
    }
    get salesAreaId(): string {
        return `${this.plant}/01/01`;
    }

    set salesAreaName(value: string | null) {
        this._salesAreaName = value;
    }

    get plant(): string | null {
        if (this._plant) {
            return this._plant;
        }

        switch (this.lang) {
            case 'de-DE':
                return '0500';
            default:
                return '0500';
        }
    }

    private _configLanguage: string | null = 'de_DE';
    get configLanguage(): string | null {
        return this._configLanguage || this.lang?.replace('-', '_');
    }
    set configLanguage(value: string | null) {
        this._configLanguage = value;
    }

    public resetServiceObs(): void {
        this.powerGridInfo$.next(null);
        this.address$.next(null);
        this.isReadOnly$.next(false);
    }

    setReadOnly() {
        this.isReadOnly$.next(true);
    }

    public createConfiguration(data: ConfigitAssignment[], model: string): Observable<ConfigurationDetails> {
        return this.http
            .post<ConfigurationDetails>(`${this.baseUrl}configurations`, {
                data,
                model,
            })
            .pipe(tap((config) => (this.orderService.configurationOwnerCompany = config.company)));
    }

    public updateConfiguration(configurationId: string, parameter: UpdateOrder): Observable<ConfigurationDetails> {
        return this.http.put<ConfigurationDetails>(`${this.baseUrl}configurations/${configurationId}`, {
            ...parameter,
            orders: this.orderService.orders.map((order) => order.orderNumber),
        });
    }

    public getConfig(configurationId: string): Observable<ConfigurationDetails> {
        return this.http
            .get<ConfigurationDetails>(`${this.baseUrl}configurations/${configurationId}`)
            .pipe(tap((config) => (this.orderService.configurationOwnerCompany = config.company)));
    }

    public getAssignments(useLandingPageAssignments?: boolean): ConfigitAssignment[] {
        return useLandingPageAssignments ? this.landingPageAssignments : this._quest?.original.assignments;
    }

    public checkModelForSystemAddress(): Observable<ConfigitAssignment[]> {
        const systemAddress = this.getSystemAddress();
        if (Object.values(systemAddress).every((v) => !!v) && !this.compareAddresses(systemAddress)) {
            this.address$.next(systemAddress);

            return this.powerGridInfoService.getPowerGrids(systemAddress).pipe(
                switchMap((gridInfo) => {
                    this.powerGridInfo$.next(gridInfo);
                    return this.getPowerGridAssignments(gridInfo);
                }),
                catchError(() => {
                    this.powerGridInfo$.next(null);
                    return this.getPowerGridAssignments();
                })
            );
        }
        return of([]);
    }

    getPowerGridAssignments(powerGridDetails?: PowerGridInfo): Observable<ConfigitAssignment[]> {
        return of([
            {
                variableName: 'Settings.GridOperatorId',
                valueText: powerGridDetails?.operator.bdewId13n || '',
                valueName: powerGridDetails?.operator.bdewId13n || '',
                isUserAssignment: true,
            },
            {
                variableName: 'PersonalData.GridOperator.Name',
                valueText: powerGridDetails?.operator.name || '',
                valueName: powerGridDetails?.operator.name || '',
                isUserAssignment: true,
            },
            {
                variableName: 'PersonalData.GridOperator.Street',
                valueText: powerGridDetails?.operator.street || '',
                valueName: powerGridDetails?.operator.street || '',
                isUserAssignment: true,
            },
            {
                variableName: 'PersonalData.GridOperator.ZipCode',
                valueText: powerGridDetails?.operator.zip || '',
                valueName: powerGridDetails?.operator.zip || '',
                isUserAssignment: true,
            },
            {
                variableName: 'PersonalData.GridOperator.Location',
                valueText: powerGridDetails?.operator.location || '',
                valueName: powerGridDetails?.operator.location || '',
                isUserAssignment: true,
            },
            {
                variableName: 'PersonalData.GridOperator.TelephoneNr',
                valueText: powerGridDetails?.operator.phone || '',
                valueName: powerGridDetails?.operator.phone || '',
                isUserAssignment: true,
            },
            {
                variableName: 'PersonalData.GridOperator.FaxNr',
                valueText: powerGridDetails?.operator.fax || '',
                valueName: powerGridDetails?.operator.fax || '',
                isUserAssignment: true,
            },
            {
                variableName: 'PersonalData.GridOperator.Url',
                valueText: powerGridDetails?.operator.url || '',
                valueName: powerGridDetails?.operator.url || '',
                isUserAssignment: true,
            },
            {
                variableName: 'PersonalData.GridOperator.EMailAddress',
                valueText: powerGridDetails?.operator.email || '',
                valueName: powerGridDetails?.operator.email || '',
                isUserAssignment: true,
            },
        ]);
    }

    public getPropertyFromAssignments(property: string) {
        return this._quest?.original?.assignments?.find((a: ConfigitAssignment) => a.variableName === property)
            ?.valueText;
    }

    public addPowerGridInfoToQuest(model?: Quest): Quest | undefined {
        const personalDataPartId = 'PersonalData';
        const systemAddressGroupId = 'PersonalData.SystemAddress';
        const personalDataPart = model?.parts?.find((part) => part.id === personalDataPartId);
        const systemGroup = personalDataPart?.groups?.find((g) => g.id === systemAddressGroupId);
        if (this.powerGridInfo$.value) {
            systemGroup?.questions?.push(...this.getPowerGridHints(this.powerGridInfo$.value));
        } else if (Object.values(this.getSystemAddress()).some((v) => !v)) {
            return model;
        } else {
            systemGroup?.questions?.push(this.getErrorHint());
        }

        return model;
    }

    public getPowerGridHints(data: PowerGridInfo): QuestQuestion[] {
        if (this.getPropertyFromAssignments('Settings.GridOperatorId') !== data.operator.bdewId13n?.toString()) {
            this.powerGridInfo$.next(data);
        }

        if (!data.operator?.bdewId13n) {
            return [this.getErrorHint()];
        }

        const operatorDescription = this.translate.instant('ADMIN_PROFI.CONFIGURATION.POWERGRID_INFO.OPERATOR', {
            name: data.operator.name || '',
            street: data.operator.street || '',
            zip: data.operator.zip || '',
            location: data.operator.location || '',
            phone: data.operator.phone || '',
            fax: data.operator.fax || '-',
            url: data.operator.url || '',
            email: data.operator.email || '',
            bdewId13n: data.operator.bdewId13n || '',
        });

        return [
            {
                id: 'PersonalData.SystemAddress.PowerGridOperator',
                type: QuestionType.hint,
                appearance: QuestionAppearance.half,
                text: this.translate.instant('ADMIN_PROFI.CONFIGURATION.POWERGRID_OPERATOR.TITLE'),
                description: operatorDescription,
                value: '',
            },
        ];
    }

    public getErrorHint(): QuestQuestion {
        return {
            id: 'PersonalData.SystemAddress.InvalidAddress',
            type: QuestionType.hint,
            appearance: QuestionAppearance.full,
            text: this.translate.instant('ADMIN_PROFI.CONFIGURATION.ERROR.INVALID_ADDRESS.TITLE'),
            description: this.translate.instant('ADMIN_PROFI.CONFIGURATION.ERROR.INVALID_ADDRESS.TEXT'),
            value: '',
        };
    }

    private compareAddresses(newAddress: Address): boolean {
        return JSON.stringify(newAddress) === JSON.stringify(this.address$.value);
    }

    public checkForInvoicePrefillment(isEplan?: boolean): Observable<ConfigitAssignment[]> {
        if (this.fetchInvoiceRecipient(isEplan)) {
            return this.identityService.user$.pipe(
                switchMap((user) => {
                    return user.identityType === 'kuma'
                        ? forkJoin([of(user), this.companyService.getCompany(user.company.id)])
                        : forkJoin([of(user)]);
                }),
                switchMap(([user, company]) => this.prefillInvoiceRecipient(user, company, isEplan)),
                catchError(() => of([]))
            );
        } else {
            return EMPTY;
        }
    }

    private getSystemAddress(): Address {
        return {
            street: this.getPropertyFromAssignments(System.STREET),
            houseNumber: this.getPropertyFromAssignments(System.HOUSE_NUMBER),
            zipCode: this.getPropertyFromAssignments(System.ZIPCODE),
            city: this.getPropertyFromAssignments(System.CITY),
        };
    }

    private getIdFromAssignments(property: string) {
        return (
            this._quest?.original?.assignments?.find((a: ConfigitAssignment) => a.variableName === property)
                ?.valueName || ''
        );
    }

    private fetchInvoiceRecipient(isEplan?: boolean): boolean {
        const invoiceRecipientVariables = isEplan ? ePlanInvoiceRecipient : adminProfiInvoiceRecipient;
        const invoiceRecipient = {
            company: this.getPropertyFromAssignments(invoiceRecipientVariables.company),
            customerNumber: this.getPropertyFromAssignments(invoiceRecipientVariables.customerNumber),
            firstName: this.getPropertyFromAssignments(invoiceRecipientVariables.firstName),
            lastName: this.getPropertyFromAssignments(invoiceRecipientVariables.lastName),
            street: this.getPropertyFromAssignments(invoiceRecipientVariables.street),
            houseNumber: this.getPropertyFromAssignments(invoiceRecipientVariables.houseNumber),
            zipCode: this.getPropertyFromAssignments(invoiceRecipientVariables.zipCode),
            city: this.getPropertyFromAssignments(invoiceRecipientVariables.city),
            phone: this.getPropertyFromAssignments(invoiceRecipientVariables.phone),
            email: this.getPropertyFromAssignments(invoiceRecipientVariables.email),
        };

        return (
            Object.values(invoiceRecipient).every((v) => !v) &&
            this.getIdFromAssignments(invoiceRecipientVariables.useLoggedInuser) === 'yes'
        );
    }

    private prefillInvoiceRecipient(user: Me, company?: Company, isEPlan?: boolean): Observable<ConfigitAssignment[]> {
        const invoiceRecipientVariables = isEPlan ? ePlanInvoiceRecipient : adminProfiInvoiceRecipient;
        return of([
            ...this._quest?.original.assignments,
            {
                variableName: invoiceRecipientVariables.company,
                valueText: company?.name,
                valueName: company?.name,
                isUserAssignment: true,
            },
            {
                variableName: invoiceRecipientVariables.customerNumber,
                valueText: company?.companyNumber,
                valueName: company?.companyNumber,
                isUserAssignment: true,
            },
            {
                variableName: invoiceRecipientVariables.firstName,
                valueText: user?.name.firstName,
                valueName: user?.name.firstName,
                isUserAssignment: true,
            },
            {
                variableName: invoiceRecipientVariables.lastName,
                valueText: user?.name.familyName,
                valueName: user?.name.familyName,
                isUserAssignment: true,
            },
            {
                variableName: invoiceRecipientVariables.street,
                valueText: company?.street?.split(/(\d+)/g)[0] || '',
                valueName: company?.street?.split(/(\d+)/g)[0] || '',
                isUserAssignment: true,
            },
            {
                variableName: invoiceRecipientVariables.houseNumber,
                valueText: company?.street.split(/(\d+)/g)[1] || '',
                valueName: company?.street.split(/(\d+)/g)[1] || '',
                isUserAssignment: true,
            },
            {
                variableName: invoiceRecipientVariables.zipCode,
                valueText: company?.postalCode,
                valueName: company?.postalCode,
                isUserAssignment: true,
            },
            {
                variableName: invoiceRecipientVariables.city,
                valueText: company?.city,
                valueName: company?.city,
                isUserAssignment: true,
            },
            {
                variableName: invoiceRecipientVariables.phone,
                valueText: user.identityType === 'kuma' ? user.contacts?.mobile?.number : user.contacts?.mobile,
                valueName: user.identityType === 'kuma' ? user.contacts?.mobile?.number : user.contacts?.mobile,
                isUserAssignment: true,
            },
            {
                variableName: invoiceRecipientVariables.email,
                valueText: user?.contacts?.email,
                valueName: user?.contacts?.email,
                isUserAssignment: true,
            },
        ]);
    }

    getMandateInformation(userName: string, projectId: string): MandateDetails {
        const subscriber = {
            name: this.getPropertyFromAssignments(MandateSubscriber.NAME),
            surname: this.getPropertyFromAssignments(MandateSubscriber.SURNAME),
            street: this.getPropertyFromAssignments(MandateSubscriber.STREET),
            housenumber: this.getPropertyFromAssignments(MandateSubscriber.HOUSE_NUMBER),
            zip: this.getPropertyFromAssignments(MandateSubscriber.ZIP),
            city: this.getPropertyFromAssignments(MandateSubscriber.CITY),
            birthplace: this.getPropertyFromAssignments(MandateSubscriber.BIRTHPLACE),
            birthday: this.getPropertyFromAssignments(MandateSubscriber.BIRTHDAY),
        };

        const constructor = {
            name: this.getPropertyFromAssignments(MandateConstructor.NAME),
            surname: this.getPropertyFromAssignments(MandateConstructor.SURNAME),
            street: this.getPropertyFromAssignments(MandateConstructor.STREET),
            housenumber: this.getPropertyFromAssignments(MandateConstructor.HOUSE_NUMBER),
            zip: this.getPropertyFromAssignments(MandateConstructor.ZIP),
            city: this.getPropertyFromAssignments(MandateConstructor.CITY),
            birthplace: this.getPropertyFromAssignments(MandateConstructor.BIRTHPLACE),
            birthday: this.getPropertyFromAssignments(MandateConstructor.BIRTHDAY),
        };

        return {
            projectId: projectId,
            username: userName,
            subscriber,
            constructor,
        };
    }

    public getFullName(result: ConfigurationDetails): string {
        const isEplan = result.model === 'E-Plan';
        const systemDetails: SystemDetails = isEplan ? ePlanSystemDetails : adminProfiSystemDetails;
        return (
            `${this.getPropertyFromResponse(result, adminProfiOperator.firstName) || ''} ${
                this.getPropertyFromResponse(result, adminProfiOperator.lastName) || ''
            }`.trim() ||
            `${this.getPropertyFromResponse(result, systemDetails.firstName) || ''} ${
                this.getPropertyFromResponse(result, systemDetails.lastName) || ''
            }`.trim()
        );
    }

    public getPropertyFromResponse(response: ConfigurationDetails, property: string) {
        return response.data?.find((a: ConfigitAssignment) => a.variableName === property)?.valueName || '';
    }
}

export interface ConfigurationDetails {
    completeDate?: string;
    createdAt: string;
    data: ConfigitAssignment[];
    projectId: string;
    company: string;
    installerLastName?: string;
    invoiceRecipientLastName?: string;
    invoiceRecipientFirstName?: string;
    street?: string;
    updatedAt?: string;
    zapId?: string;
    _id: string;
    complete?: boolean;
    invoiceRecipientFullName?: string;
    invoiceRecipientMail?: string;
    selectedEnergySources: string[];
    projectType: string;
    model?: string;
    firstName?: string;
    systemOperatorLastName?: string;
    fullName?: string;
    systemOperatorMail?: string;
    systemOperatorStreet?: string;
    status: ConfigurationStatus;
    attachments: ConfigAttachment[];
    orders: string[];
    commissioningDate?: string;
    comment?: string;
}

export interface ConfigAttachment {
    questionId: string;
    sectionId: string;
    attachmentId: string;
    fileName: string;
    url?: string;
}

export enum ConfigurationStatus {
    ORDER_ENTRY = 'ORDER_ENTRY',
    ADMINPROFI_HIRED = 'ADMINPROFI_HIRED',
    ENTRY_CHECK = 'ENTRY_CHECK',
    NETWORK_OPERATOR_CHECK = 'NETWORK_OPERATOR_CHECK',
    FEED_COMMITMENT_RECEIVED = 'FEED_COMMITMENT_RECEIVED',
    MASTER_DATA_REGISTRATION = 'MASTER_DATA_REGISTRATION',
    CLOSED = 'CLOSED',
    PAUSED = 'PAUSED',
}

export interface InvoiceRecipient {
    company: string;
    customerNumber: string;
    firstName: string;
    lastName: string;
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    phone: string;
    email: string;
    useLoggedInuser: string;
}

const adminProfiInvoiceRecipient: InvoiceRecipient = {
    company: 'PersonalData.InvoiceRecipient.Company',
    customerNumber: 'PersonalData.InvoiceRecipient.CustomerNr',
    firstName: 'PersonalData.InvoiceRecipient.FirstName',
    lastName: 'PersonalData.InvoiceRecipient.LastName',
    street: 'PersonalData.InvoiceRecipient.Street',
    houseNumber: 'PersonalData.InvoiceRecipient.HouseNumber',
    zipCode: 'PersonalData.InvoiceRecipient.ZipCode',
    city: 'PersonalData.InvoiceRecipient.City',
    phone: 'PersonalData.InvoiceRecipient.TelephoneNr',
    email: 'PersonalData.InvoiceRecipient.EMailAddress',
    useLoggedInuser: 'PersonalData.InvoiceRecipient.UseLoggedInUser',
};

const ePlanInvoiceRecipient: InvoiceRecipient = {
    company: 'EPlan.InvoiceRecipient.Company',
    customerNumber: 'EPlan.InvoiceRecipient.CustomerNr',
    firstName: 'EPlan.InvoiceRecipient.FirstName',
    lastName: 'EPlan.InvoiceRecipient.LastName',
    street: 'EPlan.InvoiceRecipient.Street',
    houseNumber: 'EPlan.InvoiceRecipient.HouseNumber',
    zipCode: 'EPlan.InvoiceRecipient.ZipCode',
    city: 'EPlan.InvoiceRecipient.City',
    phone: 'EPlan.InvoiceRecipient.TelephoneNr',
    email: 'EPlan.InvoiceRecipient.EMailAddress',
    useLoggedInuser: 'EPlan.InvoiceRecipient.UseLoggedInUser',
};
