<div class="video-dialog-container">
    <iframe
        src="https://www.youtube-nocookie.com/embed/pcaPEcQxKbA?si=cXietjvcrKdWzYLO&amp;controls=1&amp;autoplay=1"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
    ></iframe>
</div>
<div class="video-dialog-button-group">
    <button mat-flat-button mat-dialog-close color="primary">{{ 'ADMIN_PROFI.VIDEO_DIALOG.CLOSE' | translate }}</button>
</div>
