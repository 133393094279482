import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Quest, QuestCheckResult, QuestionType, QuestService, QuestValueChange } from '@vi/quest';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ConflictingValueDialogComponent } from '../components/conflicting-value-dialog/conflicting-value-dialog.component';
import { CheckEvent } from '../modules/landingpage/landing-page.component';
import { ConfigitApiService } from './configit-api.service';
import { ConfigitQuestModelTransform } from './configit-quest-model-transform';
import {
    ConfigitAssignment,
    ConfigitConfiguration,
    ConfigitConflict,
    ConfigitItem,
    ConfigitModelOriginal,
    ConfigitTemplate,
    ConfigitValue,
} from './configit.model';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class ConfigitQuestAdapterService implements QuestService {
    transformer: ConfigitQuestModelTransform;
    // amount of conflicting items to display to the user (on conflicting change)
    maxConflictingItems = 3;
    constructor(
        protected api: ConfigitApiService,
        protected dialog: MatDialog,
        private translate: TranslateService,
        private configService: ConfigurationService
    ) {
        this.transformer = new ConfigitQuestModelTransform(translate, environment.configit.imageUrl, '', configService);
    }

    /**
     * Gets quest model
     *
     * @param name Material name
     * @param existingAssignments
     */

    get(name?: string | number, existingAssignments?: ConfigitAssignment[]): Observable<Quest> {
        const params = {
            material: name,
        };

        return forkJoin([this.api.getTemplate(params), this.api.getConfiguration(params, existingAssignments)]).pipe(
            map((resp: [ConfigitTemplate, ConfigitConfiguration]) => ({
                template: resp[0],
                configuration: resp[1]['configuration'],
                bomItems: resp[1]['bomItems'],
            })),
            map(
                (model: ConfigitModelOriginal) =>
                    this.addAssignments(model, model?.configuration?.newAssignments || []) && model
            ),
            map((model: ConfigitModelOriginal) => this.transformer.transform(model))
        );
    }

    /**
     * Checks changed value
     *
     * @param changed Value
     * @param order Optional order
     */
    check(changed: CheckEvent, order?: string): Observable<QuestCheckResult> {
        const original: ConfigitModelOriginal = changed?.quest?.original;
        const name = <string>original.template.name;
        // send only user assigned as existing assignments
        const assignments = (original.assignments || []).filter((item) => item.isUserAssignment);
        // as configit throws error on undefined values
        const value = this.prepareValue(changed);
        // additionals
        const additional = { order };

        let complete = false;
        return this.api.setAssignment(name, <string>changed?.id, value, assignments, additional).pipe(
            concatMap((resp: ConfigitConfiguration) => {
                complete = !!resp?.complete;
                return this.checkConflicts(resp, !!changed.blockConflictDialog);
            }),
            map(
                (resp: ConfigitConfiguration) =>
                    this.removeAssignments(original, resp?.assignmentsToRemove || []) && resp
            ),
            map((resp: ConfigitConfiguration) => this.addAssignments(original, resp?.newAssignments || []) && resp),
            map((resp: ConfigitConfiguration) => this.mergeConfigurations(original, resp)),
            map((conf: ConfigitConfiguration) => this.transformer.transform(<ConfigitModelOriginal>conf)),
            map((transformed: Quest) => ({
                model: {
                    ...transformed,
                    original: {
                        ...transformed.original,
                        configuration: {
                            ...transformed.original.configuration,
                            complete,
                        },
                    },
                },
                errors: this.errors(),
            }))
        );
    }

    /**
     * Submits model data
     */
    submit(model: Quest): Observable<QuestCheckResult> {
        return of({
            errors: false,
            model,
        });
    }

    /**
     * Checks if conflict is returned on update configuration and prompts the user
     *
     */
    protected checkConflicts(updated: ConfigitConfiguration, blockDialog?: boolean): Observable<ConfigitConfiguration> {
        if (updated.conflict && !blockDialog) {
            // prepare conflicting items and dialog
            const items = this.conflictingItems(updated.conflict);
            const prompt = this.dialog.open(ConflictingValueDialogComponent, {
                data: items,
                disableClose: true,
            });

            return prompt.afterClosed().pipe(
                // and revert if not agreed to continue
                map((agree) => (agree && updated) || this.revertChanges(updated) || blockDialog)
            );
        } else {
            // proceed otherwise
            return of(updated);
        }
    }

    /**
     * Extracts conflicting values basis on conflict info
     *
     */
    protected conflictingItems(conflict: ConfigitConflict): string[] {
        // extract questions from default message of conflict
        // (otherwise question text should be extracted from quest questions)
        const question = (line: string) => line.match(/^(.+?) =/);
        const names = (conflict?.message || '')
            .split(/\n/)
            .map(question)
            .filter(Boolean)
            .map((part) => part?.[1] ?? '');

        // return max defined (omitting initial 'if you select...')
        return names.slice(1, this.maxConflictingItems + 1);
    }

    /**
     * Reverts changes done in configuration on update
     *
     */
    protected revertChanges(updated: ConfigitConfiguration): ConfigitConfiguration {
        updated.assignmentsToRemove = [];
        updated.newAssignments = [];
        updated.uiGroupStates = [];
        updated.variableStates = [];

        return updated;
    }

    /**
     * Prepares value for configit (empty string for none) and/or stringified for file
     *
     */
    protected prepareValue(changed: QuestValueChange): string {
        if (<QuestionType>changed?.question?.type === QuestionType.file) {
            return JSON.stringify(changed.value);
        } else {
            return changed ? changed.value : '';
        }
    }

    /**
     * Adds new assignments to original model assignments
     *
     * @param original Original model
     * @param added Added assignments
     */
    protected addAssignments(original: ConfigitModelOriginal, added: ConfigitAssignment[]): ConfigitModelOriginal {
        if (added) {
            original.assignments = [...(original.assignments || []), ...(Array.isArray(added) ? added : [added])];
        }

        return original;
    }

    /**
     * Removes assignments from original model assignments
     *
     * @param original Original model
     * @param remove Removed assignments
     */
    protected removeAssignments(original: ConfigitModelOriginal, remove: ConfigitAssignment[]): ConfigitModelOriginal {
        if (remove?.length) {
            const keys = this.objectize(remove, 'variableName');
            const removed = (item: Required<ConfigitAssignment>) =>
                !(keys[item.variableName] && keys[item.variableName].valueName === item.valueName);

            original.assignments = (<Required<ConfigitAssignment>[]>original.assignments || []).filter(removed);
        }

        return original;
    }

    /**
     * Merges original configuration within updated one
     *
     * @param original Original model
     * @param updated Updated configuaration
     * @returns Changed original
     */
    protected mergeConfigurations(
        original: ConfigitModelOriginal,
        updated: ConfigitConfiguration
    ): ConfigitModelOriginal {
        const unique = 'fullyQualifiedName';
        const states = updated?.variableStates?.concat(updated.uiGroupStates) || [];
        const update = this.objectize(states, unique);

        ['variableStates', 'uiGroupStates'].forEach((part) => {
            original.configuration[part].forEach((variable: ConfigitItem, idx: number) => {
                const next: ConfigitItem = update[<string>variable[unique]];

                if (next) {
                    if (next.valueStates) {
                        // merge values and assign to original configuration
                        const values = this.mergeValues(variable?.valueStates || [], next.valueStates);
                        original.configuration[part][idx] = {
                            ...next,
                            valueStates: values,
                        };
                    } else if (next.invalidMessage) {
                        // update only message as previous value could be unknown (see checkErrors on ConfigitApiService)
                        original.configuration[part][idx].invalidMessage = this.translate.instant(
                            `${environment.quest.context}.${next.invalidMessage}`
                        );
                        original.configuration[part][idx].invalidValue = next.invalidValue;
                        original.configuration[part][idx].valid = false;
                    } else {
                        // just update
                        original.configuration[part][idx] = next;
                    }
                } else if (variable.invalidValue !== undefined) {
                    // if no next (which can clear previous invalid) unset invalid
                    original.configuration[part][idx].invalidMessage = undefined;
                    original.configuration[part][idx].invalidValue = undefined;
                }
            });
        });

        return original;
    }

    /**
     * Merges values of original and updated after set assignment
     *
     */
    protected mergeValues(original: ConfigitValue[], updated: ConfigitValue[]): unknown[] {
        // map name driven
        const unique = 'name';
        const prev = this.objectize(original, unique);
        const curr = this.objectize(updated, unique);
        // then replace only changed
        for (const [key, value] of Object.entries(curr)) {
            prev[key] = value;
        }

        return Object.values(prev);
    }

    protected errors(): boolean {
        return false;
    }

    private objectize(data: any[], prop: string): { [key: string]: any } {
        const result: { [key: string]: any } = {};

        data.forEach((item) => (result[item[prop]] = item));

        return result;
    }
}
