<h2 class="fw-bold mb-1" mat-dialog-title>
    <!-- Order search -->
    {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.TITLE' | translate }}
</h2>
<div *ngIf="status === 'Default'">
    <div class="row border-bottom mb-3">
        <div class="d-flex col align-items-center">
            <mat-form-field appearance="outline">
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input
                        [placeholder]="'ADMIN_PROFI.ORDER_SEARCH_DIALOG.INPUT.LABEL.START' | translate"
                        formControlName="start"
                        matStartDate
                    />
                    <input
                        [placeholder]="'ADMIN_PROFI.ORDER_SEARCH_DIALOG.INPUT.LABEL.END' | translate"
                        formControlName="end"
                        matEndDate
                    />
                </mat-date-range-input>
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error
                    *ngIf="range.controls['start'].hasError('required') || range.controls['end'].hasError('required')"
                    >{{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.INPUT.ERROR.INVALID_DATE' | translate }}</mat-error
                >
            </mat-form-field>
        </div>
        <div class="col d-flex align-items-center">
            <button (click)="searchOrders()" [disabled]="range.invalid" color="primary" mat-flat-button>
                <!--      Search        -->
                {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.BUTTON.SEARCH' | translate }}
            </button>
        </div>
    </div>

    <cdk-virtual-scroll-viewport appendOnly class="viewport" itemSize="10">
        <div *cdkVirtualFor="let order of orders; let i = index; let first = first" class="py-1 border-bottom">
            <div *ngIf="first" class="row border-bottom pb-2">
                <div class="col-1"></div>
                <div class="col-3 d-flex justify-content-start">
                    {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.TITLE_ORDER_NUMBER' | translate }}
                </div>
                <div class="col-5 d-flex justify-content-start">
                    {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.TITLE_SHORT_TEXT' | translate }}
                </div>
                <div class="col-3 d-flex justify-content-start">
                    {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.TITLE_CREATION_DATE' | translate }}
                </div>
            </div>
            <div (click)="select(i)" [class.disabled]="order.disabled" class="row click-row">
                <div class="col-1 d-flex justify-content-center">
                    <mat-checkbox
                        (change)="select(i)"
                        (click)="$event.stopPropagation()"
                        [checked]="!!order?.selected"
                        [disabled]="order.disabled"
                        class="ps-2"
                    ></mat-checkbox>
                </div>
                <div class="col-3 d-flex justify-content-start">
                    {{ order.orderNumber }}
                </div>
                <div class="col-5 d-flex justify-content-start">
                    {{ order?.customerPurchaseNumber || '-' }}
                </div>
                <div class="col-3 d-flex justify-content-start">
                    {{ order.documentCreationDate ? (order.documentCreationDate | date) : '-' }}
                </div>
            </div>
        </div>
        <div *ngIf="!orders.length">
            <!--   No orders found     -->
            {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.NOTHING_FOUND' | translate }}
        </div>
    </cdk-virtual-scroll-viewport>
    <p class="text-small">
        {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.SEARCH_HINT' | translate }}
    </p>
</div>
<ng-container *ngIf="status === 'Pending'">
    <div class="d-flex row justify-content-center text-center">
        <mat-spinner color="accent"></mat-spinner>
        <!--  Loading orders...    -->
        <p>{{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.LOADING' | translate }}</p>
    </div>
</ng-container>
<div *ngIf="status === 'Error'" class="row">
    <div class="col">
        <p class="color-red">
            <!-- An error has occurred. Please contact support. -->
            {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.ERROR.GENERAL' | translate }}
        </p>
    </div>
</div>
<div class="d-flex justify-content-end mt-3">
    <button (click)="cancel()" class="me-2" mat-button>
        <!--   Cancel   -->
        {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.BUTTON.CANCEL' | translate }}
    </button>
    <button (click)="confirm()" [disabled]="!selectedOrders.length" color="primary" mat-flat-button>
        <!--   confirm   -->
        {{ 'ADMIN_PROFI.ORDER_SEARCH_DIALOG.BUTTON.CONFIRM' | translate }}
    </button>
</div>
