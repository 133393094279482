import { Injectable } from '@angular/core';
import { ConfigitAssignment } from './configit.model';
import { ConfigurationService } from './configuration.service';
import { OrderItem, ProductDetails } from './order.service';

@Injectable({
    providedIn: 'root',
})
export class ComponentValidationService {
    // As described in BTPD-11228
    public readonly supportedPvIds = ['08'];

    // As described in BTPD-11895
    public readonly supportedInverterIds = ['18', '35', '36'];
    public readonly allowedInverterManufactures = ['01', '03', '04', '05', '07'];

    // As described in BTPD-11896
    public readonly supportedStorageIds = ['09', '25'];
    public readonly allowedStorageManufactures = ['01', '02', '03', '04'];

    // As described in BTPD-11897
    public readonly supportedChargingStationAndWallBoxIds = ['65'];
    public readonly allowedChargingStationAndWallBoxManufactures = ['01', '03', '09'];

    // As described in BTPD-11897
    public readonly supportedBackupBoxIds = ['64'];
    public readonly allowedBackupBoxManufactures = ['01', '03', '09'];

    // As described in BTPD-11958
    public readonly supportedHeatPumpIds = ['03'];

    constructor(private configurationService: ConfigurationService) {}

    public filterRelevantOrderItems(items: OrderItem[]): OrderItem[] {
        return items
            .filter(
                (item) =>
                    this.isPvModule(item) ||
                    this.isInverter(item) ||
                    this.isStorage(item) ||
                    this.isHeatPump(item) ||
                    this.isChargingStationOrWallBox(item) ||
                    this.isBackupBox(item)
            )
            .map((item) => {
                return {
                    ...item,
                    selected: false,
                    disabled: false,
                };
            });
    }

    // Some products consist of several components.
    // Therefore, we need to check if the bill of materials contains a component of the relevant device category.
    public isPvModule(item: OrderItem): boolean {
        return (
            item.details?.billOfMaterial.some((material) => this.supportedPvIds.includes(material.deviceCategoryId)) ||
            false
        );
    }

    // Some products consist of several components.
    // Therefore, we need to check if the bill of materials contains a component of the relevant device category.
    public isInverter(item: OrderItem): boolean {
        return (
            (item.details?.billOfMaterial.some((material) =>
                this.supportedInverterIds.includes(material.deviceCategoryId)
            ) &&
                this.isSupportedInverter(item)) ||
            false
        );
    }

    // Some products consist of several components.
    // Therefore, we need to check if the bill of materials contains a component of the relevant device category.
    public isStorage(item: OrderItem): boolean {
        return (
            (item.details?.billOfMaterial.some((material) =>
                this.supportedStorageIds.includes(material.deviceCategoryId)
            ) &&
                this.isSupportedStorage(item)) ||
            false
        );
    }

    // Some products consist of several components.
    // Therefore, we need to check if the bill of materials contains a component of the relevant device category.
    // Currently, it is not possible to distinguish between a wallbox and a charging station. Both have deviceCategory 64
    public isChargingStationOrWallBox(item: OrderItem): boolean {
        return (
            (item.details?.billOfMaterial.some((material) =>
                this.supportedChargingStationAndWallBoxIds.includes(material.deviceCategoryId)
            ) &&
                this.isSupportedChargingStationOrWallBox(item)) ||
            false
        );
    }

    // Some products consist of several components.
    // Therefore, we need to check if the bill of materials contains a component of the relevant device category.
    public isBackupBox(item: OrderItem): boolean {
        return (
            (item.details?.billOfMaterial.some((material) =>
                this.supportedBackupBoxIds.includes(material.deviceCategoryId)
            ) &&
                this.isSupportedBackupBox(item)) ||
            false
        );
    }

    // Some products consist of several components.
    // Therefore, we need to check if the bill of materials contains a component of the relevant device category.
    public isHeatPump(item: OrderItem): boolean {
        return (
            item.details?.billOfMaterial.some((material) =>
                this.supportedHeatPumpIds.includes(material.deviceCategoryId)
            ) || false
        );
    }

    public getExternalAssignments(selectedItems: OrderItem[]): ConfigitAssignment[] {
        // Return only new technical external assignments, since the component selection may have been changed.
        return [
            ...this.configurationService.quest?.original.assignments.filter(
                (assigment: { variableName: string }) =>
                    !assigment.variableName.includes('External.') || assigment.variableName === 'External.CreatorMail'
            ),
            ...this.getExternalPvAssignments(selectedItems),
            ...this.getExternalInverterAssignments(selectedItems),
            ...this.getExternalStorageAssignments(selectedItems),
            ...this.getExternalChargingStationAndWallBoxAssignments(selectedItems),
            ...this.getExternalBackUpBoxAssignments(selectedItems),
            ...this.getExternalHeatPumpAssignments(selectedItems),
        ];
    }

    private isSupportedInverter(item: OrderItem): boolean {
        return this.allowedInverterManufactures.includes(
            item.details?.technicalData.find((data) => data.key === 'AT_SAP_HERSTELLER_ANZEIGE')?.value || ''
        );
    }

    private isSupportedStorage(item: OrderItem): boolean {
        return this.allowedStorageManufactures.includes(
            item.details?.technicalData.find((data) => data.key === 'AT_SAP_HERSTELLER_ANZEIGE_BAT')?.value || ''
        );
    }

    private isSupportedChargingStationOrWallBox(item: OrderItem): boolean {
        return this.allowedChargingStationAndWallBoxManufactures.includes(
            item.details?.technicalData.find((data) => data.key === 'AT_SAP_HERSTELLER_ANZEIGE')?.value || ''
        );
    }

    private isSupportedBackupBox(item: OrderItem): boolean {
        return this.allowedBackupBoxManufactures.includes(
            item.details?.technicalData.find((data) => data.key === 'AT_SAP_HERSTELLER_ANZEIGE')?.value || ''
        );
    }

    private getExternalPvAssignments(selectedItems: OrderItem[]): ConfigitAssignment[] {
        const pvModule = selectedItems
            .filter((item) => this.isPvModule(item))
            .map((item) => item.details)
            .filter(Boolean)[0];

        if (!pvModule) {
            return [];
        }

        const pvDetails = this.mapTechnicalPvDetails(selectedItems, pvModule);

        return [
            {
                variableName: externalPVAssignments.numberOfPvModules,
                valueText: `${pvDetails.numberOfPvModules}`,
                valueName: `${pvDetails.numberOfPvModules}`,
                isUserAssignment: true,
            },
            {
                variableName: externalPVAssignments.powerPerModule,
                valueText: pvDetails.powerPerModule,
                valueName: pvDetails.powerPerModule,
                isUserAssignment: true,
            },
            {
                variableName: externalPVAssignments.productTye,
                valueText: pvDetails.productTye,
                valueName: pvDetails.productTye,
                isUserAssignment: true,
            },
        ];
    }

    // Mapping rules for PV modules: BTPD-11228
    private mapTechnicalPvDetails(selectedItems: OrderItem[], product: ProductDetails): PVDetails {
        // Some products consist of several components.
        // Therefore, we need to find the relevant material number and the associated technical details in the bill of materials.
        const relevantMaterial = product.billOfMaterial.find((item) => item.deviceCategoryId === '08')?.materialNumber;
        return {
            numberOfPvModules:
                selectedItems.find((item) =>
                    item.details?.billOfMaterial.find(
                        (material) => material.deviceCategoryId === '08' && material.materialNumber === relevantMaterial
                    )
                )?.quantity || 0,
            powerPerModule:
                product.technicalData.find(
                    (data) => data.key === 'AT_SAP_LEISTUNG_STC' && data.materialNumber === relevantMaterial
                )?.value || '',
            productTye:
                `${
                    product.technicalData.find(
                        (data) => data.key === 'AT_SAP_PRODUKTNAME' && data.materialNumber === relevantMaterial
                    )?.value
                } ${
                    product.technicalData.find(
                        (data) => data.key === 'AT_SAP_PRODUKTTYP' && data.materialNumber === relevantMaterial
                    )?.value
                }` || '',
        };
    }

    private getExternalInverterAssignments(selectedItems: OrderItem[]): ConfigitAssignment[] {
        const inverters = selectedItems
            .filter((item) => this.isInverter(item))
            .map((item) => item.details)
            .filter(Boolean);

        if (!inverters) {
            return [];
        }

        const inverterDetails = this.mapInverterDetails(inverters);

        return [
            {
                variableName: externalInverterAssignments.numberOfInverters,
                valueName: `${inverterDetails.length}`,
                valueText: `${inverterDetails.length}`,
                isUserAssignment: true,
            },
            ...inverterDetails.flatMap((inverter, index) => {
                return [
                    {
                        variableName: `${externalInverterAssignments.inverterBaseKey}${index + 1}${
                            externalInverterAssignments.manufacturer
                        }`,
                        valueName: inverter.manufacturer,
                        valueText: inverter.manufacturer,
                        isUserAssignment: true,
                    },
                    {
                        variableName: `${externalInverterAssignments.inverterBaseKey}${index + 1}${
                            externalInverterAssignments.type
                        }`,
                        valueName: inverter.type,
                        valueText: inverter.type,
                        isUserAssignment: true,
                    },
                    {
                        variableName: `${externalInverterAssignments.inverterBaseKey}${index + 1}${
                            externalInverterAssignments.gridFeedType
                        }`,
                        valueName: inverter.gridFeedType,
                        valueText: inverter.gridFeedType,
                        isUserAssignment: true,
                    },
                    {
                        variableName: `${externalInverterAssignments.inverterBaseKey}${index + 1}${
                            externalInverterAssignments.productGroup
                        }`,
                        valueName: inverter.productGroup,
                        valueText: inverter.productGroup,
                        isUserAssignment: true,
                    },
                ];
            }),
        ];
    }

    // Mapping rules for inverters: BTPD-11895 / BTPD-12320
    private mapInverterDetails(products: (ProductDetails | undefined)[]): InverterDetails[] {
        return products.map((product) => {
            // Some products consist of several components.
            // For example, the Vitocharge. Its bill of materials contains an inverter and a power storage unit.
            // Therefore, we need to find the relevant material number and the associated technical details in the bill of materials.
            const relevantMaterial = product?.billOfMaterial.find((item) =>
                this.supportedInverterIds.includes(item.deviceCategoryId)
            )?.materialNumber;
            return {
                manufacturer:
                    product?.technicalData.find(
                        (data) => data.key === 'AT_SAP_HERSTELLER_ANZEIGE' && data.materialNumber === relevantMaterial
                    )?.value || '',
                type:
                    product?.technicalData.find(
                        (data) => data.key === 'AT_SAP_TYP' && data.materialNumber === relevantMaterial
                    )?.value || '',
                gridFeedType:
                    product?.technicalData.find(
                        (data) => data.key === 'AT_SAP_AC_NETZANSCHLUSS' && data.materialNumber === relevantMaterial
                    )?.value || '',
                productGroup:
                    product?.billOfMaterial.find((data) => data.materialNumber === relevantMaterial)
                        ?.deviceCategoryId || '',
            };
        });
    }

    private getExternalStorageAssignments(selectedItems: OrderItem[]): ConfigitAssignment[] {
        const storages = selectedItems
            .filter((item) => this.isStorage(item))
            .map((item) => {
                return { details: item.details, quantity: item.quantity };
            })
            .filter(Boolean);

        const storageDetails = this.mapTechnicalStorageDetails(storages);

        return storageDetails.flatMap((storage, index) => {
            return [
                {
                    variableName: `${externalStorageAssignments.numberOfBatteries}${index + 1}`,
                    valueName: storage.numberOfBatteries.toString() || storageDetails.length.toString(),
                    valueText: storage.numberOfBatteries.toString() || storageDetails.length.toString(),
                    isUserAssignment: true,
                },
                {
                    variableName: `${externalStorageAssignments.storageBaseKey}${index + 1}${
                        externalStorageAssignments.manufacturer
                    }`,
                    valueName: storage.manufacturer,
                    valueText: storage.manufacturer,
                    isUserAssignment: true,
                },
                {
                    variableName: `${externalStorageAssignments.storageBaseKey}${index + 1}${
                        externalStorageAssignments.type
                    }`,
                    valueName: storage.type,
                    valueText: storage.type,
                    isUserAssignment: true,
                },
                {
                    variableName: `${externalStorageAssignments.storageBaseKey}${index + 1}${
                        externalStorageAssignments.capacity
                    }`,
                    valueName: storage.capacity,
                    valueText: storage.capacity,
                    isUserAssignment: true,
                },
            ];
        });
    }

    // Mapping rules for energy storages / batteries: BTPD-11896
    private mapTechnicalStorageDetails(
        storages: { quantity: number; details: ProductDetails | undefined }[]
    ): StorageDetails[] {
        // Some products consist of several components.
        // Therefore, we need to find the relevant material number and the associated technical details in the bill of materials.
        return storages.map((product) => {
            const relevantMaterial = product?.details?.billOfMaterial.find((item) =>
                this.supportedStorageIds.includes(item.deviceCategoryId)
            )?.materialNumber;
            const relevantBillOfMaterial = product?.details?.billOfMaterial.find(
                (material) =>
                    this.supportedStorageIds.includes(material.deviceCategoryId) &&
                    material.materialNumber === relevantMaterial
            );
            return {
                numberOfBatteries: !relevantBillOfMaterial
                    ? product.quantity
                    : (relevantBillOfMaterial?.quantity === 1 ? product.quantity : relevantBillOfMaterial?.quantity) ||
                      0,
                manufacturer:
                    product?.details?.technicalData.find(
                        (data) =>
                            data.key === 'AT_SAP_HERSTELLER_ANZEIGE_BAT' && data.materialNumber === relevantMaterial
                    )?.value || '',
                type:
                    product?.details?.technicalData.find(
                        (data) => data.key === 'AT_SAP_TYP_BATTERIESPEICHER' && data.materialNumber === relevantMaterial
                    )?.value || '',
                capacity:
                    product?.details?.technicalData.find(
                        (data) => data.key === 'AT_SAP_NUTZ_KAPAZITAET' && data.materialNumber === relevantMaterial
                    )?.value || '',
            };
        });
    }

    // Mapping rules for charging stations / wall boxes: BTPD-11897 / BTPD-12320
    private getExternalChargingStationAndWallBoxAssignments(selectedItems: OrderItem[]): ConfigitAssignment[] {
        const chargingStationsAndWallBoxes = selectedItems
            .filter((item) => this.isChargingStationOrWallBox(item))
            .map((item) => item.details)
            .filter(Boolean);
        return [
            {
                variableName: externalChargingStationAndWallBoxAssignments.numberOfPlannedChargingStationsOrWallBoxes,
                valueText: chargingStationsAndWallBoxes.length.toString(),
                valueName: chargingStationsAndWallBoxes.length.toString(),
                isUserAssignment: true,
            },
            {
                variableName: externalChargingStationAndWallBoxAssignments.chargingStationOrWallBoxSelected,
                valueText: chargingStationsAndWallBoxes.length ? 'yes' : 'no',
                valueName: chargingStationsAndWallBoxes.length ? 'yes' : 'no',
                isUserAssignment: true,
            },
        ];
    }

    // Mapping rules for charging backup boxes: BTPD-11897
    private getExternalBackUpBoxAssignments(selectedItems: OrderItem[]): ConfigitAssignment[] {
        const backUpBoxes = selectedItems
            .filter((item) => this.isBackupBox(item))
            .map((item) => item.details)
            .filter(Boolean);

        return [
            {
                variableName: externalBackupBoxAssignments.boxSelected,
                valueText: backUpBoxes.length ? 'yes' : 'no',
                valueName: backUpBoxes.length ? 'yes' : 'no',
                isUserAssignment: true,
            },
        ];
    }

    private getExternalHeatPumpAssignments(selectedItems: OrderItem[]): ConfigitAssignment[] {
        const heatPumps = selectedItems
            .filter((item) => this.isHeatPump(item))
            .map((item) => item.details)
            .filter(Boolean);

        if (!heatPumps) {
            return [];
        }

        const heatPumpDetails = this.mapTechnicalHeatPumpDetails(heatPumps);

        return [
            {
                variableName: externalHeatPumpAssignments.numberOfHeatPumps,
                valueText: `${heatPumpDetails.length}`,
                valueName: `${heatPumpDetails.length}`,
                isUserAssignment: true,
            },
            ...heatPumpDetails.flatMap((heatPump, index) => {
                return [
                    {
                        variableName: `${externalHeatPumpAssignments.heatPumpBaseKey}${index + 1}${
                            externalHeatPumpAssignments.type
                        }`,
                        valueName: heatPump.type,
                        valueText: heatPump.type,
                        isUserAssignment: true,
                    },
                    {
                        variableName: `${externalHeatPumpAssignments.heatPumpBaseKey}${index + 1}${
                            externalHeatPumpAssignments.productGroup
                        }`,
                        valueName: heatPump.productGroup,
                        valueText: heatPump.productGroup,
                        isUserAssignment: true,
                    },
                    {
                        variableName: `${externalHeatPumpAssignments.heatPumpBaseKey}${index + 1}${
                            externalHeatPumpAssignments.heatingRodHeatingLoad
                        }`,
                        valueName: heatPump.heatingRodHeatingLoad,
                        valueText: heatPump.heatingRodHeatingLoad,
                        isUserAssignment: true,
                    },
                ];
            }),
        ];
    }

    // Mapping rules for heat pumps: BTPD-11958
    private mapTechnicalHeatPumpDetails(products: (ProductDetails | undefined)[]): HeatPumpDetails[] {
        return products.map((product) => {
            // Some products consist of several components.
            // Therefore, we need to find the relevant material number and the associated technical details in the bill of materials.
            const relevantMaterial = product?.billOfMaterial.find((item) =>
                this.supportedHeatPumpIds.includes(item.deviceCategoryId)
            )?.materialNumber;
            return {
                type:
                    `${
                        product?.technicalData.find(
                            (data) => data.key === 'AT_SAP_PRODUKTNAME' && data.materialNumber === relevantMaterial
                        )?.value
                    } ${
                        product?.technicalData.find(
                            (data) => data.key === 'AT_SAP_PRODUKTTYP' && data.materialNumber === relevantMaterial
                        )?.value
                    }` || '',
                productGroup:
                    product?.technicalData.find(
                        (data) => data.key === 'AT_SAP_ENEV_GERAETEART_NEU' && data.materialNumber === relevantMaterial
                    )?.value || '',
                heatingRodHeatingLoad:
                    product?.technicalData.find(
                        (data) =>
                            data.key === 'AT_SAP_HEIZLEIST_HW_DURCHLAUF' && data.materialNumber === relevantMaterial
                    )?.value || '',
            };
        });
    }
}

const externalPVAssignments = {
    numberOfPvModules: 'External.NumberOfPVModules',
    powerPerModule: 'External.PowerPerModule',
    productTye: 'External.ProductType',
};

export interface PVDetails {
    numberOfPvModules: number;
    powerPerModule: string;
    productTye: string;
}

const externalInverterAssignments = {
    numberOfInverters: 'External.NumberOfInverters',
    inverterBaseKey: 'External.Inverter',
    manufacturer: 'Manufacturer',
    type: 'Type',
    gridFeedType: 'GridFeed',
    productGroup: 'Group',
};

export interface InverterDetails {
    manufacturer: string;
    type: string;
    gridFeedType: string;
    productGroup: string;
}

const externalStorageAssignments = {
    numberOfBatteries: 'External.NumberOfBatteries',
    storageBaseKey: 'External.Battery',
    manufacturer: 'Manufacturer',
    type: 'Type',
    capacity: 'Capacity',
};

export interface StorageDetails {
    numberOfBatteries: number;
    manufacturer: string;
    type: string;
    capacity: string;
}

const externalChargingStationAndWallBoxAssignments = {
    numberOfPlannedChargingStationsOrWallBoxes: 'External.NumberOfChargingStations',
    chargingStationOrWallBoxSelected: 'External.ChargingStation',
};

const externalBackupBoxAssignments = {
    boxSelected: 'External.BackupBox',
};

const externalHeatPumpAssignments = {
    numberOfHeatPumps: 'External.NumberOfHeatPumps',
    heatPumpBaseKey: 'External.HeatPump',
    type: 'ProductType',
    productGroup: 'ProductGroup',
    heatingRodHeatingLoad: 'HeatingRodHeatingLoad',
};

export interface HeatPumpDetails {
    type: string;
    productGroup: string;
    heatingRodHeatingLoad: string;
}
