import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { switchMap, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { IdentityService } from '../services/identity.service';

export const authGuard: CanActivateFn = () => {
    const auth: AuthService = inject(AuthService);
    const identityService: IdentityService = inject(IdentityService);
    const router: Router = inject(Router);
    return auth.getCsrfToken$().pipe(
        switchMap(() => identityService.hasAdminProfiAccess$),
        tap((hasAccess) => {
            if (!hasAccess) {
                router.navigate(['/forbidden']);
            }
        })
    );
};
