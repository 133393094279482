import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { Order, OrderService } from '../../../services/order.service';

interface SearchForm {
    start: FormControl<string | null>;
    end: FormControl<string | null>;
}

@Component({
    selector: 'app-order-search-dialog',
    templateUrl: './order-search-dialog.component.html',
    styleUrls: ['./order-search-dialog.component.scss'],
})
export class OrderSearchDialogComponent implements OnInit, OnDestroy {
    status: 'Pending' | 'Default' | 'Error' | 'Submitting' = 'Default';
    orders: Order[] = [];
    selectedOrders: Order[] = [];
    unsubscribe$: Subject<boolean> = new Subject<boolean>();
    readonly dateFormat = 'YYYY-MM-DD';
    // Initially search the orders from the last month
    range = new FormGroup<SearchForm>({
        start: new FormControl<string>(moment().subtract(1, 'month').format(this.dateFormat), [Validators.required]),
        end: new FormControl<string>(moment().format('YYYY-MM-DD'), [Validators.required]),
    });

    constructor(private orderService: OrderService, private dialogRef: MatDialogRef<OrderSearchDialogComponent>) {}

    ngOnInit(): void {
        this.searchOrders();
    }

    searchOrders() {
        this.selectedOrders = [];
        this.status = 'Pending';
        this.orderService
            .searchOrders(
                moment(this.range.value.start).format(this.dateFormat),
                moment(this.range.value.end).format(this.dateFormat)
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.orders = this.orderService.orders;
                    this.selectedOrders = this.orderService.orders.filter((order) => order.selected);
                    this.status = 'Default';
                },
                error: () => {
                    this.status = 'Error';
                },
            });
    }

    select(index: number) {
        this.orders[index].selected = !this.orders[index].selected;
        this.selectedOrders = this.orders.filter((item) => !!item.selected);
    }

    cancel() {
        this.dialogRef.close(this.orderService.orders.filter((order) => order.disabled));
    }

    confirm() {
        this.dialogRef.close(this.selectedOrders);
    }

    ngOnDestroy() {
        this.unsubscribe$.next(true);
    }
}
