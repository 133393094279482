<!--Thank you-->
<h3 class="px-0">{{ 'ADMIN_PROFI.THANKS.TITLE' | translate }}</h3>
<!--isEplan ? We create a Circuit diagram for you.... : We will submit your project registration...-->

<p>
    {{
        (isEplan ? 'ADMIN_PROFI.THANKS.EPLAN' : 'ADMIN_PROFI.THANKS.ADMIN_PROFI_PROJECTID')
            | translate : { id: projectId }
    }}
</p>

<a mat-raised-button color="primary" *ngIf="downloadConfiguration" [href]="getDownloadUrl()">
    {{ 'ADMIN_PROFI.THANKS.DOWNLOAD' | translate }}
</a>
