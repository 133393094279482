import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MandateService {
    constructor(private http: HttpClient) {}

    generateMandate(mandateDetails: MandateDetails): Observable<Blob> {
        return this.http.post(`${environment.http.baseUrl}mandates`, mandateDetails, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
    }
}

export interface MandateDetails {
    projectId?: string;
    username?: string;
    subscriber?: {
        name?: string;
        surname?: string;
        street?: string;
        housenumber?: string;
        zip?: string;
        city?: string;
        birthplace?: string;
        birthday?: string;
    };
    constructor?: {
        name?: string;
        surname?: string;
        street?: string;
        housenumber?: string;
        zip?: string;
        city?: string;
        birthplace?: string;
        birthday?: string;
    };
}
