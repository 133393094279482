import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    private baseUrl = environment.http.baseUrl;

    public getDownloadUrl(filename: string, inline: boolean): string {
        const query = inline ? '?inline=true' : '';
        return `${this.baseUrl}downloads/${filename}${query}`;
    }
}
