<ng-container *ngIf="!isEplan">
    <!-- The prices depend on the selected package... -->
    <p>
        {{ 'ADMIN_PROFI.CONFIRMATION.TEXT' | translate }}
    </p>
    <ul>
        <!-- Package x costs y... -->
        <li [innerHtml]="'ADMIN_PROFI.CONFIRMATION.PACKAGE.' + projectType | translate : { price: this.price }"></li>
    </ul>
</ng-container>

<ng-container *ngIf="isEplan">
    <!-- The costs for EPlan apply before acceptance of... -->
    <p [innerHtml]="'ADMIN_PROFI.CONFIRMATION.PACKAGE.' + projectType | translate : { price: this.price }"></p>
</ng-container>

<!--Price plus VAT...-->
<p class="text-small">{{ 'ADMIN_PROFI.CONFIRMATION.LEGAL_HINT' | translate }}</p>

<!--Attention Adminview. You are submitting for a customer...-->
<p *ngIf="isAdmin" class="text-center">
    {{ 'ADMIN_PROFI.CONFIRMATION.ADMIN_HINT' | translate }}
</p>

<!--Submit bindingly now-->
<div class="d-flex flex-row justify-content-center mt-3">
    <button (click)="submit()" [disabled]="submitting" class="submit-btn" color="warn" mat-flat-button>
        {{ 'ADMIN_PROFI.CONFIRMATION.BUTTON' | translate }}
    </button>
</div>
