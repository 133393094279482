<h2 class="fw-bold mb-1" mat-dialog-title>{{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.TITLE' | translate }}</h2>
<div class="border-bottom">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-11 d-flex justify-content-start">
            <p>{{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.DESCRIPTION' | translate }}</p>
        </div>
    </div>
</div>
<ng-container *ngIf="!!data?.length">
    <cdk-virtual-scroll-viewport appendOnly class="viewport" itemSize="10">
        <div *cdkVirtualFor="let item of data; let i = index; trackBy: trackByIdentity" class="py-1 border-bottom">
            <div (click)="select(item, i)" [class.disabled]="item.disabled" class="row click-row">
                <div class="col-1 d-flex justify-content-center">
                    <mat-checkbox
                        (change)="select(item, i)"
                        (click)="$event.preventDefault()"
                        [checked]="!!item?.selected"
                        [disabled]="item.disabled"
                        class="ps-2"
                        disableRipple
                    ></mat-checkbox>
                </div>
                <div class="col-11 d-flex justify-content-start">
                    <p>{{ item.shortText }} ({{ item.quantity }})</p>
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</ng-container>
<ng-container *ngIf="!data?.length">
    <div class="viewport">
        <p class="text-center my-3">{{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.NO_COMPONENTS_HINT' | translate }}</p>
    </div>
</ng-container>
<ng-container *ngIf="selectedPvModules.length >= allowedNumberOfPvModules"
    ><p class="limit-hint">{{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.PV_LIMIT_HINT' | translate }}</p></ng-container
>
<ng-container *ngIf="selectedInverters.length >= allowedNumberOfInverters"
    ><p class="limit-hint">
        {{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.INVERTER_LIMIT_HINT' | translate }}
    </p></ng-container
>
<ng-container *ngIf="selectedStorages.length >= allowedNumberOfStorages"
    ><p class="limit-hint">
        {{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.STORAGE_LIMIT_HINT' | translate }}
    </p></ng-container
>
<ng-container *ngIf="selectedChargingStationsOrWallBoxes.length >= allowedNumberOfChargingStationsOrWallBoxes"
    ><p class="limit-hint">
        {{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.CHARGING_AND_BOXES_LIMIT_HINT' | translate }}
    </p></ng-container
>
<ng-container *ngIf="selectedHeatPumps.length >= allowedNumberOfHeatPumps"
    ><p class="limit-hint">
        {{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.HEAT_PUMP_LIMIT_HINT' | translate }}
    </p></ng-container
>

<div class="d-flex justify-content-end mt-3">
    <button [mat-dialog-close]="false" mat-button>
        {{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.BUTTON.CANCEL' | translate }}
    </button>
    <button (click)="closeDialog()" [disabled]="!selectedItems.length" color="primary" mat-flat-button>
        {{ 'ADMIN_PROFI.COMPONENT_SELECTION_DIALOG.BUTTON.CONFIRM' | translate }}
    </button>
</div>
