import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { InstanaService } from './services/instana.service';
@Component({
    selector: 'app-component',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;
    constructor(private instana: InstanaService, private auth: AuthService, private router: Router) {}

    ngOnInit(): void {
        this.auth.init();
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.instana.reportPage(val.urlAfterRedirects);
            }
        });
    }

    toggleSideNav() {
        this.sidenav.toggle();
    }
}
