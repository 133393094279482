import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IdentityService } from '../../../../services/identity.service';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
    @Input() isEplan = false;
    @Input() submitting = false;

    @Output() submitEvent = new EventEmitter();

    isAdmin = false;

    projectType = '';
    price: string = '';

    constructor(public identityService: IdentityService, private configurationService: ConfigurationService) {}

    ngOnInit(): void {
        this.identityService.isAdmin$.subscribe((isAdmin) => (this.isAdmin = isAdmin));

        this.projectType = this.configurationService.getPropertyFromAssignments('LandingPage.ServiceName');
        this.price = this.configurationService.getPropertyFromAssignments('LandingPage.Price');
    }

    public submit() {
        this.submitEvent.emit();
    }
}
