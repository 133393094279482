import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalHeaderModule } from '@vi/global-header';
import { environment } from '../../../environments/environment';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SecondaryNavigationComponent } from './components/secondary-navigation/secondary-navigation.component';

const material = [MatToolbarModule, MatIconModule, MatButtonModule];

@NgModule({
    declarations: [NavigationComponent, SecondaryNavigationComponent],
    imports: [
        ...material,
        CommonModule,
        GlobalHeaderModule.forRoot({
            appId: 'admin-profi',
            baseUrl: environment.auth.baseUrl,
            accountAppUrl: environment.auth.registerBaseUrl,
        }),
        TranslateModule,
        RouterModule,
    ],
    exports: [NavigationComponent, SecondaryNavigationComponent],
})
export class NavigationModule {}
