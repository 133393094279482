<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title
            ><h3>{{ 'ADMIN_PROFI.DASHBOARD.TITLE' | translate }}</h3></mat-panel-title
        >
    </mat-expansion-panel-header>
    <div class="dashboard">
        <div class="dashboard-content">
            <div class="content-description">
                {{ 'ADMIN_PROFI.DASHBOARD.CONTENT.DESCRIPTION' | translate }}
            </div>
            <div class="content-item" *ngFor="let section of uploadSections">
                <div class="item-header">
                    <h5>
                        {{ 'ADMIN_PROFI.DASHBOARD.SECTION.TITLE.' + section.id.toUpperCase() | translate }}
                    </h5>
                </div>
                <div class="item-upload" *ngIf="section.options">
                    <div class="upload-container">
                        <div class="upload-pending" *ngIf="isPending(section.id)">
                            <mat-spinner diameter="40"></mat-spinner>
                            <div class="pending-hint">{{ 'ADMIN_PROFI.DASHBOARD.UPLOAD.PENDING' | translate }}</div>
                        </div>
                        <div class="upload-dropzone" *ngIf="!manageAccess(section.id)">
                            <input
                                type="file"
                                ngFileSelect
                                [multiple]="section.multiple"
                                [options]="section.options"
                                [uploadInput]="startUpload"
                                (uploadOutput)="onUpload($event, section.id)"
                                [attr.accept]="section.options.allowedContentTypes?.join()"
                                [disabled]="manageAccess(section.id)"
                                #input
                            />
                            <div class="dropzone-interface">
                                <mat-icon>description</mat-icon>
                                {{ 'ADMIN_PROFI.DASHBOARD.UPLOAD.DROPZONE' | translate }}
                            </div>
                        </div>
                        <div
                            class="upload-hint"
                            *ngIf="manageAccess(section.id) && !atLeastOneFileInSection(section.id)"
                        >
                            {{ 'ADMIN_PROFI.DASHBOARD.UPLOAD.NO_FILE_IN_SECTION' | translate }}
                        </div>
                        <div
                            class="upload-hint"
                            *ngIf="manageAccess(section.id) && atLeastOneFileInSection(section.id)"
                        >
                            {{ 'ADMIN_PROFI.DASHBOARD.UPLOAD.FILE_IN_SECTION' | translate }}
                        </div>
                        <div class="upload-storage" *ngIf="atLeastOneFileInSection(section.id)">
                            <div
                                class="upload-storage-item"
                                [ngClass]="{
                                    queued: uploadFile.queued,
                                    uploading: uploadFile.uploading,
                                    uploaded: uploadFile.control.value,
                                    dragging: dragging,
                                    error: uploadFile.control.hasError('max') || uploadFile.control.hasError('upload'),
                                    hidden: uploadFile.sectionId !== section.id
                                }"
                                *ngFor="let uploadFile of uploadFileControls"
                            >
                                <ng-container *ngIf="uploadFile.sectionId === section.id">
                                    <div class="upload-storage-progress">
                                        <div class="progress-details">
                                            <div class="progress-icon">
                                                <mat-spinner [diameter]="24"></mat-spinner>
                                            </div>
                                            <div class="progress-content">
                                                {{
                                                    'ADMIN_PROFI.DASHBOARD.UPLOAD.UPLOADING'
                                                        | translate : uploadFile.uploading
                                                }}
                                            </div>
                                        </div>
                                        <div class="progress-actions">
                                            <button mat-icon-button (click)="cancel(uploadFile)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="upload-storage-queue" *ngIf="uploadFile.control.valid">
                                        <div class="queue-icon">
                                            <mat-spinner [diameter]="24"></mat-spinner>
                                        </div>
                                        <div class="queue-content">
                                            {{ 'ADMIN_PROFI.DASHBOARD.UPLOAD.QUEUE' | translate }}
                                        </div>
                                    </div>
                                    <div class="upload-storage-file">
                                        <div class="file-details">
                                            <div
                                                class="file-icon"
                                                [ngClass]="{
                                                    success: uploadFile.control.valid
                                                }"
                                            >
                                                <mat-icon *ngIf="uploadFile.control.valid && !uploadFile.deleting"
                                                    >check_circle</mat-icon
                                                >
                                                <mat-icon
                                                    *ngIf="!uploadFile.control.valid && !uploadFile.deleting"
                                                    color="error"
                                                    >cancel</mat-icon
                                                >
                                                <mat-spinner diameter="24" *ngIf="uploadFile.deleting"></mat-spinner>
                                            </div>
                                            <div class="file-name">
                                                <span *ngIf="uploadFile.deleting">
                                                    {{
                                                        'ADMIN_PROFI.DASHBOARD.UPLOAD.DELETING'
                                                            | translate : uploadFile.control.value
                                                    }}
                                                </span>
                                                <span *ngIf="!uploadFile.deleting">
                                                    {{
                                                        'ADMIN_PROFI.DASHBOARD.UPLOAD.UPLOADED'
                                                            | translate : uploadFile.control.value
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="uploadFile.file && !uploadFile.file.nativeFile">
                                            <div class="file-actions">
                                                <a
                                                    mat-icon-button
                                                    [href]="downloadUrl(uploadFile.file.id)"
                                                    target="_blank"
                                                    [matTooltip]="'ADMIN_PROFI.DASHBOARD.TOOLTIP.DOWNLOAD' | translate"
                                                    [matTooltipShowDelay]="500"
                                                >
                                                    <mat-icon>download</mat-icon>
                                                </a>
                                                <button
                                                    mat-icon-button
                                                    (click)="deleteAttachment(uploadFile)"
                                                    [disabled]="uploadInProgress()"
                                                    [matTooltip]="'ADMIN_PROFI.DASHBOARD.TOOLTIP.DELETE' | translate"
                                                    *ngIf="!manageAccess(section.id)"
                                                >
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="uploadFile.control.hasError('max')" class="upload-storage-error">
                                        {{ 'ADMIN_PROFI.DASHBOARD.UPLOAD.ERROR.MAX' | translate }}
                                    </div>
                                    <div *ngIf="uploadFile.control.hasError('upload')" class="upload-storage-error">
                                        {{ 'ADMIN_PROFI.DASHBOARD.UPLOAD.ERROR.UPLOAD' | translate }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="upload-action" *ngIf="!manageAccess(section.id)">
                        <button
                            mat-raised-button
                            color="primary"
                            (click)="saveUploadSection(section.id)"
                            [disabled]="!atLeastOneFileInSection(section.id) || sectionUploading"
                        >
                            {{ 'ADMIN_PROFI.DASHBOARD.UPLOAD.' + section.id.toUpperCase() + '.SAVE' | translate }}
                        </button>
                    </div>
                </div>
                <div class="item-date" *ngIf="!section.options">
                    <div class="item-subject">
                        <mat-form-field>
                            <input
                                matInput
                                [matDatepicker]="picker"
                                (dateChange)="setCommissioningDate($event)"
                                [value]="commissioningDate | date : 'dd.MM.YYYY'"
                                [placeholder]="
                                    (commissioningDate | date : 'dd.MM.YYYY') ||
                                    ('ADMIN_PROFI.DASHBOARD.COMMISSIONING_DATE.PLACEHOLDER' | translate)
                                "
                                [disabled]="disableCustomerCommissioningDate"
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="date-action">
                        <button
                            mat-raised-button
                            color="primary"
                            (click)="saveDateSection()"
                            *ngIf="!disableCustomerCommissioningDate"
                            [disabled]="!commissioningDate || sectionUploading"
                        >
                            {{ 'ADMIN_PROFI.DASHBOARD.UPLOAD.' + section.id.toUpperCase() + '.SAVE' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-expansion-panel>
