<div class="flex-row d-flex justify-content-end">
    <button mat-icon-button>
        <mat-icon (click)="closeIconPressed.emit()">close</mat-icon>
    </button>
</div>

<div class="flex-row">
    <ul class="px-5">
        <li class="">
            <a class="" [href]="'ADMIN_PROFI.NAVIGATION.LINK.SERVICE' | translate" (click)="closeIconPressed.emit()">{{
                'ADMIN_PROFI.NAVIGATION.TEXT.SERVICE' | translate
            }}</a>
        </li>
        <li class="my-3">
            <a class="" [href]="'ADMIN_PROFI.NAVIGATION.LINK.FAQ' | translate" (click)="closeIconPressed.emit()">{{
                'ADMIN_PROFI.NAVIGATION.TEXT.FAQ' | translate
            }}</a>
        </li>
        <li class="my-3">
            <a [href]="'ADMIN_PROFI.NAVIGATION.LINK.ABOUT_US' | translate" (click)="closeIconPressed.emit()">{{
                'ADMIN_PROFI.NAVIGATION.TEXT.ABOUT_US' | translate
            }}</a>
        </li>
        <li>
            <a routerLink="/overview" class="" (click)="closeIconPressed.emit()">{{
                'ADMIN_PROFI.NAVIGATION.TEXT.REQUESTS' | translate
            }}</a>
        </li>
    </ul>
</div>
