import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { forkJoin, map, switchMap, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { IdentityService } from '../services/identity.service';

export const adminAuthGuard: CanActivateFn = () => {
    const auth = inject(AuthService);
    const identityService: IdentityService = inject(IdentityService);
    const router: Router = inject(Router);
    return auth.getCsrfToken$().pipe(
        switchMap(() =>
            forkJoin([identityService.isAdmin$, identityService.hasAdminProfiAccess$]).pipe(
                map(([isAdmin, hasAccess]) => isAdmin && hasAccess)
            )
        ),
        tap((hasAccessToAdminView) => {
            if (!hasAccessToAdminView) {
                router.navigate(['']);
            }
        })
    );
};
