import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    constructor(private http: HttpClient) {}

    getCompany(customerNumber: string): Observable<Company> {
        return this.http.get<Company>(`${environment.http.company}companies/${customerNumber}`);
    }
}

export interface Company {
    companyNumber: string;
    name: string;
    street: string;
    postalCode: string;
    city: string;
}
