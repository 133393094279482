import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceNavigationComponent } from './service-navigation.component';

@NgModule({
    declarations: [ServiceNavigationComponent],
    imports: [CommonModule, TranslateModule, MatButtonModule, RouterModule],
    exports: [ServiceNavigationComponent],
})
export class ServiceNavigationModule {}
