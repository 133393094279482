<h1 mat-dialog-title>{{ 'quest.dialogs.conflicting.title' | translate }}</h1>
<div mat-dialog-content [innerHTML]="'quest.dialogs.conflicting.message' | translate"></div>
<ul>
    <li *ngFor="let item of items" [innerHtml]="item"></li>
</ul>
<div mat-dialog-content [innerHTML]="'quest.dialogs.conflicting.question' | translate"></div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-button [mat-dialog-close]="false">
        {{ 'quest.dialogs.cancel' | translate }}
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
        {{ 'quest.dialogs.continue' | translate }}
    </button>
</div>
