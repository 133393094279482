import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-secondary-navigation',
    templateUrl: './secondary-navigation.component.html',
    styleUrls: ['./secondary-navigation.component.scss'],
})
export class SecondaryNavigationComponent {
    @Output() closeIconPressed: EventEmitter<never> = new EventEmitter<never>();
}
