<div class="container py-5">
    <div *ngIf="status === 'Pending'" class="row d-flex justify-content-center text-center pt-5">
        <mat-spinner color="accent"></mat-spinner>
        <p class="mt-3">{{ 'ADMIN_PROFI.CONFIGURATION.LOADING.TEXT' | translate }}</p>
    </div>

    <div
        *ngIf="status === 'Default' || showThanksScreen || status === 'Submitting'"
        class="flex-md-row flex-column d-flex justify-content-md-between gap-3 mb-3"
    >
        <div class="flex-md-row flex-column d-flex justify-content-md-between gap-3">
            <a *ngIf="!showConfirmationHint" mat-raised-button routerLink="/">
                <div class="d-flex align-items-center justify-content-center">
                    <mat-icon>chevron_left</mat-icon>
                    <!--    Back to overview          -->
                    {{ 'ADMIN_PROFI.CONFIGURATION.BUTTON.BACK_TO_OVERVIEW' | translate }}
                </div>
            </a>
            <button mat-raised-button color="warn" (click)="openVideoDialog()">
                {{ 'ADMIN_PROFI.CONFIGURATION.BUTTON.VIDEO' | translate }}
            </button>
        </div>
        <button
            (click)="showConfirmationHint = !showConfirmationHint"
            *ngIf="showConfirmationHint"
            [disabled]="status === 'Submitting'"
            mat-raised-button
        >
            <div class="d-flex align-items-center justify-content-center">
                <mat-icon>chevron_left</mat-icon>
                <!--    Back to request   -->
                {{ 'ADMIN_PROFI.CONFIGURATION.BUTTON.BACK_TO_CONFIG' | translate }}
            </div>
        </button>
        <div class="flex-md-row flex-column d-flex justify-content-md-between gap-3">
            <button *ngIf="isAdmin" mat-raised-button (click)="manuallySaveConfig()" [disabled]="saveButtondisabled">
                {{ 'ADMIN_PROFI.CONFIGURATION.BUTTON.ADMIN_SAVE' | translate }}
            </button>
            <a
                *ngIf="isAdmin"
                [class.pe-none]="status === 'Submitting'"
                [disabled]="status === 'Submitting'"
                [routerLink]="['/admin', configId, 'project', projectId]"
                mat-raised-button
            >
                <!--    open admin view        -->
                {{ 'ADMIN_PROFI.CONFIGURATION.BUTTON.OPEN_ADMIN_VIEW' | translate }}
            </a>
        </div>
    </div>
    <div [class.complete]="isCompleted" [hidden]="showConfirmationHint || showThanksScreen || status === 'Pending'">
        <div [hidden]="(configurationService.isReadOnly$ | async) && status === 'Default'">
            <app-order-search (update)="updateWithExternalAssignments($event)"></app-order-search>
        </div>
        <app-dashboard
            [hidden]="showConfirmationHint || showThanksScreen || status === 'Pending'"
            [isAdmin]="isAdmin"
            [configurationId]="configId"
        ></app-dashboard>
        <vi-quest
            [model]="quest$"
            (check)="check($event)"
            (partChange)="handlePartChange($event)"
            (submit)="checkIfComplete($event)"
        ></vi-quest>
        <div *ngIf="showMandateBtn" class="d-flex flex-row justify-content-center">
            <button (click)="generateMandate()" color="primary" mat-raised-button>
                {{ 'ADMIN_PROFI.CONFIGURATION.BUTTON.GENERATE_MANDATE' | translate }}
            </button>
        </div>

        <div *ngIf="showNotCompletedHint" class="bg-white complete-hint mt-3">
            <h4>{{ 'ADMIN_PROFI.CONFIGURATION.NOT_COMPLETED_HINT.TITLE' | translate }}</h4>
            <p>
                {{ 'ADMIN_PROFI.CONFIGURATION.NOT_COMPLETED_HINT.TEXT' | translate }}
            </p>
        </div>
    </div>

    <ng-container *ngIf="showConfirmationHint">
        <mat-progress-bar *ngIf="status === 'Submitting'" mode="query"></mat-progress-bar>
        <div class="bg-white complete-hint">
            <app-confirmation
                (submitEvent)="submit()"
                [isEplan]="isEplan"
                [submitting]="status === 'Submitting'"
            ></app-confirmation>
        </div>
    </ng-container>
    <div *ngIf="showThanksScreen && status !== 'Submitting'" class="bg-white complete-hint mt-3">
        <app-thanks [isEplan]="isEplan" [projectId]="projectId" [isAdmin]="isAdmin" [configId]="configId"></app-thanks>
    </div>
</div>
